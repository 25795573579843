define("questmap/templates/components/upload-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2CXjHQqw",
    "block": "{\"symbols\":[\"dropzone\",\"queue\",\"&default\"],\"statements\":[[4,\"parts/pre-loader\",null,[[\"loading\"],[[24,[\"loading\"]]]],{\"statements\":[[4,\"file-dropzone\",null,[[\"name\"],[\"photos\"]],{\"statements\":[[4,\"file-upload\",null,[[\"name\",\"multiple\",\"onfileadd\"],[\"photos\",true,[28,\"action\",[[23,0,[]],\"uploadImage\"],null]]],{\"statements\":[[0,\"            \"],[14,3],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null]],\"parameters\":[]},null],[0,\"  \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/components/upload-image.hbs"
    }
  });

  _exports.default = _default;
});