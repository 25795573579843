define("questmap/routes/app/magic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return this.store.findAll('magic');
    },

    actions: {
      create(t) {
        let model = this.store.createRecord('magic', {
          title: t
        });
        model.save();
      }

    }
  });

  _exports.default = _default;
});