define("questmap/services/adjustments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    modal: Ember.inject.service(),

    async create(modelName, row, model) {
      if (this.get('currentUser.user.role') !== 'Супер пользователь') return;
      let m = this.get('store').createRecord(modelName, {
        [row]: model.id
      });
      this.modal.open('modals.adjustment', m);
    }

  });

  _exports.default = _default;
});