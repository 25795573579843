define("questmap/routes/app/profiles/view/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    modal: Ember.inject.service(),

    model() {
      return this.modelFor("app.profiles.view").get('id');
    },

    async setupController(controller, model) {
      controller.set('columns', [{
        label: 'Имя',
        valuePath: 'name'
      }, {
        label: 'Почта',
        valuePath: 'email'
      }, {
        label: 'Доступ',
        valuePath: 'access',
        cellComponent: 'tables/true-false'
      }, {
        label: 'Роль',
        valuePath: 'role'
      }, {
        label: 'Последний визит',
        valuePath: 'last_visit',
        cellComponent: 'tables/unix'
      }]);
      controller.set('constParams', {
        profile: model
      });
    },

    actions: {
      createUser(param) {
        let user = this.store.createRecord("user", param);
        this.modal.open('modals/user', user);
      }

    }
  });

  _exports.default = _default;
});