define("questmap/models/questroom", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: (0, _emberCpValidations.validator)('cantblank'),
    title_latine: (0, _emberCpValidations.validator)('titleLatine')
  });

  var _default = Model.extend(Validations, {
    title: attr('string'),
    title_latine: attr('string'),
    company: attr('string'),
    address: attr('string'),
    howToFind: attr('string'),
    parking: attr('string'),
    phone: attr('string'),
    category: attr('string'),
    type: attr('string'),
    video: attr('string'),
    is_corporatives: attr('boolean'),
    is_addMetroManually: attr('boolean'),
    event_zone_size: attr('string'),
    event_zone_price: attr('string'),
    event_zone_for: attr('string'),
    players_min: attr('number'),
    players_max: attr('number'),
    time: attr('number'),
    age: attr('number'),
    difficulty: attr('string'),
    fear: attr('string'),
    //отзывы
    delta_k: attr('string'),
    delta: attr('string'),
    orders_count: attr('number'),
    reviews_count: attr('number'),
    access: attr('boolean'),
    api_type: attr('string'),
    get_link: attr('string'),
    post_link: attr('string'),
    price_link: attr('string'),
    price_title: attr('string'),
    secret_key: attr('string'),
    teaser: attr('string'),
    scanword: attr('string'),
    description: attr('string'),
    legend: attr('string'),
    image: attr('string'),
    images: attr('array'),
    tags: attr('array'),
    profile: attr('string'),
    latitude: attr('string'),
    longitude: attr('string'),
    disctrict: attr('array'),
    city: attr('string'),
    cityFront: Ember.computed('city', function () {
      if (this.city === undefined) {
        return 'Москва';
      }

      return `${this.city}`;
    }),
    citySelect: Ember.A(['Москва', 'Санкт-Петербург']),
    metro: attr('array'),
    metroName: Ember.computed('metro', function () {
      const subway = this.metro.map(item => item.title);
      console.log(subway);
      return Ember.A(subway);
    })
  });

  _exports.default = _default;
});