define("questmap/routes/app/profiles/view/questrooms/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let m = this.modelFor("app.profiles.view");
      return this.store.createRecord("questroom", {
        profile: m.get('id'),
        address: m.get('address'),
        city: m.get('city'),
        company: m.get('group_title'),
        phone: '+74991334218',
        images: Ember.A()
      });
    }

  });

  _exports.default = _default;
});