define("questmap/templates/components/tables/table-accept", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lA0OOLPc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[11,\"class\",[29,[\"visit_ico \",[24,[\"row\",\"status\"]]]]],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/components/tables/table-accept.hbs"
    }
  });

  _exports.default = _default;
});