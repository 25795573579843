define("questmap/transforms/phone", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var deserialize = function (v) {
    return `+7 (${v.slice(1, 4)}) ${v.slice(4, 7)}-${v.slice(7, 9)}-${v.slice(9, 11)}`;
  };

  var serialize = function (v) {
    let phone = v ? '' + v.replace(/[^0-9]/gim, '') : false;
    return phone ? 7 + phone.slice(1) : '';
  };

  var _default = _emberData.default.Transform.extend({
    deserialize(serialized) {
      return deserialize(serialized);
    },

    serialize(deserialized) {
      return serialize(deserialized);
    }

  });

  _exports.default = _default;
});