define("questmap/routes/app/categories/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    crud: Ember.inject.service(),

    setupController(controller) {
      controller.set("columns", [{
        label: "Название",
        valuePath: "title"
      }, {
        label: "Подкатегории",
        valuePath: "types",
        cellComponent: 'tables/has-many'
      }, {
        label: "Кол-во заведений",
        valuePath: "placesCount"
      }]);
    }

  });

  _exports.default = _default;
});