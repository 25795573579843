define("questmap/templates/app/partners/view/orders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WBDhpLay",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"filters/place-orders\",null,[[\"places\",\"searchParams\",\"constParams\"],[[24,[\"places\"]],[24,[\"searchParams\"]],[24,[\"constParams\"]]]]],false],[0,\"\\n\\n\"],[4,\"unless\",[[24,[\"modal\",\"refresh\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"tables/table-index\",null,[[\"hideSearch\",\"columns\",\"height\",\"constParams\",\"searchParams\",\"modelName\"],[true,[24,[\"columns\"]],\"65vh\",[24,[\"constParams\"]],[24,[\"searchParams\"]],\"place-order\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/app/partners/view/orders.hbs"
    }
  });

  _exports.default = _default;
});