define("questmap/templates/components/bootstrap/form-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mMVn0XY5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"control-label\"],[8],[1,[22,\"label\"],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"input-group\"],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"input-group-addon\"],[8],[1,[22,\"span\"],false],[9],[0,\"\\n        \"],[1,[28,\"input\",null,[[\"class\",\"value\"],[\"form-group form-control\",[24,[\"value\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/components/bootstrap/form-group.hbs"
    }
  });

  _exports.default = _default;
});