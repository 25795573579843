define("questmap/templates/components/tables/player/orders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y7I084IN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[10,\"class\",\"allTag\"],[8],[1,[24,[\"row\",\"not_accepted_orders\"]],false],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"acceptedTag\"],[8],[1,[24,[\"row\",\"accepted_orders_count\"]],false],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"canceledTag\"],[8],[1,[24,[\"row\",\"canceled_orders_count\"]],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/components/tables/player/orders.hbs"
    }
  });

  _exports.default = _default;
});