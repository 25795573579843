define("questmap/components/active-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['cat_row'],
    classNameBindings: ['active'],
    active: Ember.computed('values.[]', function () {
      return this.values && this.values.includes(this.value);
    }),
    actions: {
      add() {
        const model = this.get('model');
        const action = model.get(this.row).includes(this.value) ? 'removeObject' : 'pushObject';
        model.get(this.row)[action](this.value);
      }

    }
  });

  _exports.default = _default;
});