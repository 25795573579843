define("questmap/validators/title-latine", ["exports", "ember-cp-validations/validators/base", "questmap/config/environment"], function (_exports, _base, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const TitleLatine = _base.default.extend({
    ajax: Ember.inject.service(),

    async validate(value, options, model, attribute) {
      if (!value.trim().length) {
        return 'Необходимо ввести латинское название';
      }

      const {
        uniq
      } = await fetch(`${_environment.default.host}/questrooms/is_tl_uniq`, {
        method: 'POST',
        body: JSON.stringify({
          title_latine: value,
          id: model.id
        })
      }).then(response => response.json());
      return uniq ? uniq : 'Значение не уникально';
    }

  });

  TitleLatine.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     * 	`model.array.@each.${attribute}` --> Dependent is created on the model's context
     * 	`${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array}
     */
    getDependentsFor()
    /* attribute, options */
    {
      return [];
    }

  });
  var _default = TitleLatine;
  _exports.default = _default;
});