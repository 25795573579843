define("questmap/routes/app/players/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller) {
      controller.set('columns', [{
        label: 'Статус',
        valuePath: 'not_save',
        cellComponent: 'tables/player/notsave',
        width: '100px'
      }, {
        label: 'Имя',
        valuePath: 'name',
        cellComponent: 'tables/player/info',
        width: '180px'
      }, {
        label: 'Город',
        valuePath: 'cityFront',
        width: '180px'
      }, {
        label: 'Почта',
        valuePath: 'email',
        cellComponent: 'tables/player/contacts',
        width: '180px'
      }, {
        label: 'Баланс',
        valuePath: 'balance',
        width: '100px',
        cellComponent: 'tables/bonus',
        align: 'center'
      }, {
        label: 'Игр',
        valuePath: 'orders',
        cellComponent: 'tables/player/orders',
        width: '200px',
        align: 'center'
      }, {
        label: 'Отзывов',
        valuePath: 'reviews_count',
        width: '10%',
        align: 'center'
      }, {
        label: 'Доверие',
        valuePath: 'trust',
        width: '110px',
        align: 'center'
      }, {
        label: 'Посл бронь',
        valuePath: 'last_order',
        cellComponent: 'tables/unix',
        width: '130px'
      }]); //controller.send('search');
    }

  });

  _exports.default = _default;
});