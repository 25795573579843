define("questmap/routes/app/payments/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    async setupController(controller, model) {
      controller.set('columns', [{
        label: 'Дата',
        cellComponent: 'tables/table-date',
        valuePath: 'createdAt'
      }, {
        label: 'Организация',
        valuePath: 'profile.title',
        cellComponent: 'tables/link',
        linkPath: 'app.profiles.view',
        linkId: 'profile'
      }, {
        label: 'Сумма',
        valuePath: 'sum'
      }, {
        label: 'Платеж прошел',
        valuePath: 'accepted',
        cellComponent: 'tables/true-false'
      }, {
        label: 'Код платежа',
        valuePath: 'paymentId'
      }, {
        label: 'Код ошибки',
        valuePath: 'error'
      }]);
    }

  });

  _exports.default = _default;
});