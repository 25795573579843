define("questmap/routes/app/agents/view/balance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return this.modelFor("app.agents.view").get('id');
    },

    async setupController(controller, model) {
      controller.set('columns', [{
        label: '',
        valuePath: 'status',
        cellComponent: 'tables/orders/status',
        width: '10px',
        sortable: false
      }, {
        label: 'Игра',
        cellComponent: 'tables/orders/title',
        sortable: false
      }, {
        label: 'Статус',
        cellComponent: 'tables/orders/changed-status',
        sortable: false
      }, {
        label: 'Цена',
        cellComponent: 'tables/orders/changed-price',
        sortable: false
      }, {
        label: 'Изменение баланса',
        valuePath: 'agent_fee',
        cellComponent: 'tables/orders/fee',
        align: 'center',
        sortable: false
      }, {
        label: 'Остаток',
        valuePath: 'agent_balance',
        cellComponent: 'tables/orders/fee',
        align: 'center',
        sortable: false
      }, {
        label: 'Дата создания',
        valuePath: 'createdAt',
        cellComponent: 'tables/table-date',
        sortable: false
      }, {
        label: 'Автор',
        cellComponent: 'tables/orders/author',
        sortable: false
      }]);
      controller.set('constParams', {
        agent: model
      });
    }

  });

  _exports.default = _default;
});