define("questmap/components/place-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    discounts: Ember.A(["10%", "12%", "15%", "20%", "25%", "30%"]),
    disctricts: Ember.A(["ЦАО", "САО", "СВАО", "ВАО", "ЮВАО", "ЮАО", "ЮЗАО", "ЗАО", "СЗАО", "Зеленоградский АО", "Новомосковский АО", "Троицкий АО"]),
    disctrictsSPB: Ember.A(["Адмиралтейский", "Василеостровский", "Выборгский", "Калининский", "Кировский", "Колпинский", "Красногвардейский", "Красносельский", "Кронштадский", "Курортный", "Московский", "Невский", "Петроградский", "Петродворцовый", "Приморский", "Пушкинский", "Фрунзенский", "Центральный"]),
    parking: Ember.A(["Нет", "Платная стихийная", "Бесплатная стихийная", "Бесплатная своя"]),
    city: Ember.A(['Москва', 'Санкт-Петербург']),
    actions: {
      save(model) {
        this.crud.save(model, "app.partners.view.places.index");
      },

      destroy(model) {
        this.crud.destroy(model, "app.partners.view.places.index");
      },

      addMain(img) {
        this.set("model.image", img);
      },

      destroyImage(i) {
        this.get("model.images").removeObject(i);
      },

      newReview(row) {
        let review = this.get("store").createRecord("place-review", {
          fake: true,
          accepted: true,
          place: row
        });
        this.modal.open("modals.place-review", review);
      }

    }
  });

  _exports.default = _default;
});