define("questmap/components/mp3-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement() {
      const that = this;
      audiojs.events.ready(function () {
        var as = audiojs.createAll({
          css: false,
          autoplay: true,
          trackEnded: function () {
            that.set('audioUrl', null);
          }
        });
      });
    },

    actions: {
      close() {
        this.set('audioUrl', null);
      }

    }
  });

  _exports.default = _default;
});