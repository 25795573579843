define("questmap/components/forms/reset-password", ["exports", "questmap/models/forms/login"], function (_exports, _login) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    alerts: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    ajax: Ember.inject.service(),
    model: Ember.computed(function () {
      return _login.default.create(Ember.getOwner(this).ownerInjection());
    }),
    actions: {
      async submit(model) {
        try {
          await this.get("ajax").request(`/useradmins/resetPassword?email=${model.identification}`);
          this.router.transitionTo('login');
          this.alerts.showAlert('success', "Пароль выслан на почту");
        } catch (err) {
          this.alerts.showAlert('error', err.message);
        }
      }

    }
  });

  _exports.default = _default;
});