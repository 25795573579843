define("questmap/components/filters/place-orders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),

    didInsertElement() {
      if (this.withoutStartRange) return this.getSearchParams();
      this.set('range', {
        start: moment().subtract(14, 'days').toDate(),
        end: moment().toDate()
      });
    },

    search: Ember.observer('place.id', 'range.start', 'range.end', function () {
      this.getSearchParams();
    }),

    getSearchParams() {
      let searchParams = {};

      if (this.range && this.range.start && this.range.end) {
        searchParams.createdAt = {
          ">=": moment(this.range.start).valueOf(),
          "<=": moment(this.range.end).endOf('day').valueOf()
        };
      }

      if (this.place) searchParams.place = this.place.id;
      this.set("searchParams", searchParams);
      if (this.constParams) searchParams = Object.assign(searchParams, this.constParams);
      this.get('ajax').request('/placeOrders/getStatistic?' + jQuery.param(searchParams)).then(s => {
        this.set('statistic', s);
      });
    }

  });

  _exports.default = _default;
});