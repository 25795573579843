define("questmap/templates/components/tables/player/notsave", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9jL0lcW/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[8],[0,\" \"],[4,\"if\",[[24,[\"row\",\"not_save\"]]],null,{\"statements\":[[0,\"  \"],[1,\"Супер\",false],[0,\" \"]],\"parameters\":[]},{\"statements\":[[0,\" \"],[1,\"Обычный\",false],[0,\" \"]],\"parameters\":[]}],[0,\" \"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/components/tables/player/notsave.hbs"
    }
  });

  _exports.default = _default;
});