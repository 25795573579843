define("questmap/routes/app/players/view/place-reviews", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return this.modelFor("app.players.view").get("id");
    },

    async setupController(controller, model) {
      controller.set("columns", [{
        label: "Игрок",
        valuePath: "player_name",
        cellComponent: "tables/reviews/player",
        width: "200px"
      }, {
        label: "Дата",
        valuePath: "createdDate",
        cellComponent: "tables/unix"
      }, {
        label: "Оценка",
        valuePath: "delta"
      }, {
        label: "Отзыв",
        valuePath: "description",
        cellComponent: "tables/reviews/review"
      }, {
        label: "Черная жемч",
        valuePath: "whitePearl",
        cellComponent: "tables/true-false"
      }, {
        label: "Белая жемч",
        valuePath: "blackPearl",
        cellComponent: "tables/true-false"
      }, {
        label: "Одобр",
        valuePath: "accepted",
        cellComponent: "tables/true-false",
        align: "center",
        width: "100px"
      }]);
      controller.set("constParams", {
        player: model
      });
    }

  });

  _exports.default = _default;
});