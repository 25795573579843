define("questmap/models/forms/login", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    identification: (0, _emberCpValidations.validator)('cantblank')
  });

  var _default = Ember.Object.extend(Validations, {
    identification: null,
    password: null
  });

  _exports.default = _default;
});