define("questmap/templates/components/bootstrap/form-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Cz8l1hsx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-group form-check\"],[8],[0,\"\\n\\t\"],[1,[28,\"input\",null,[[\"type\",\"id\",\"checked\",\"class\"],[\"checkbox\",[24,[\"title\"]],[24,[\"value\"]],\"form-check-input\"]]],false],[0,\"\\n\\t\"],[7,\"label\",true],[10,\"class\",\"form-check-label\"],[11,\"for\",[29,[[22,\"title\"]]]],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/components/bootstrap/form-checkbox.hbs"
    }
  });

  _exports.default = _default;
});