define("questmap/routes/app/questrooms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    async setupController(controller, model) {
      controller.set('columns', [{
        label: 'Название',
        valuePath: 'title',
        cellComponent: 'tables/link',
        linkPath: 'app.profiles.view',
        linkId: 'profile'
      }, {
        label: 'Запись открыта',
        valuePath: 'access',
        cellComponent: 'tables/true-false',
        width: '160px',
        align: 'center'
      }, {
        label: 'Кол-во игр',
        valuePath: 'orders_count',
        width: '160px',
        align: 'center'
      }, {
        label: 'Рейтинг',
        valuePath: 'delta',
        width: '160px',
        align: 'center'
      }, {
        label: 'Кол-во отзывов',
        valuePath: 'reviews_count',
        width: '160px',
        align: 'center'
      }, {
        label: 'Новый отзыв',
        action: this.newReview,
        title: "Написать",
        cellComponent: 'tables/button'
      }, {
        label: 'Все отзывы',
        action: this.hideAllReviews,
        title: "Скрыть",
        width: '100px',
        cellComponent: 'tables/button'
      }, {
        label: 'Все отзывы',
        action: this.showAllReviews,
        title: "Показать",
        width: '100px',
        cellComponent: 'tables/button'
      }]);
    },

    newReview(row) {
      let review = this.get("store").createRecord('review', {
        fake: true,
        accepted: true,
        questroom: row.content
      });
      this.modal.open('modals.review', review);
    },

    hideAllReviews(row) {
      let title = row.get('title');
      this.store.query('review', {
        questroom_title: title,
        limit: -1
      }).then(function (review) {
        console.log("review", review);
        review.forEach(element => {
          element.hidden = true;
          element.save();
        });
      });
      this.alerts.showAlert('success', 'Все отзывы квеста скрыты!');
    },

    showAllReviews(row) {
      let title = row.get('title');
      this.store.query('review', {
        questroom_title: title,
        limit: -1
      }).then(function (review) {
        console.log("review", review);
        review.forEach(element => {
          element.hidden = false;
          element.save();
        });
      });
      this.alerts.showAlert('success', 'Все отзывы квеста открыты!');
    }

  });

  _exports.default = _default;
});