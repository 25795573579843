define("questmap/services/alerts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const statuses = {
    'success': 'Все данные сохранены',
    'warning': 'Заполните все поля',
    'error': 'Ошибка сервера'
  };

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    // async updateModel(modelName, id) {
    //     let model = this.get('store').peekRecord(modelName, id);
    //     await model.reload();
    // },
    showAlert(status, message) {
      const flashMessages = Ember.get(this, 'flashMessages');

      if (status == "success") {
        flashMessages.success(message || statuses[status]);
      } else {
        flashMessages.danger(message || statuses[status]);
      }
    },

    confirmation() {
      $.confirm({
        title: 'Удаление элемента',
        content: 'Вы действительно хотите это удалить?',
        confirm: function () {
          return true;
        }
      });
    }

  }); // import Ember from 'ember';
  // export default Ember.Service.extend({
  //     // transition(transition, model) {
  //     //     var that = this;
  //     //     if (model.get('hasDirtyAttributes')) {
  //     //         if (that.get('flag') == undefined) {
  //     //             transition.abort();
  //     //             $.confirm({
  //     //                 title: '<i class="fa fa-floppy-o alertIcon"></i> ' + this.get('i18n').t('lll.ns').string,
  //     //                 content: this.get('i18n').t('lll.ns2').string,
  //     //                 confirm: function() {
  //     //                     model.rollbackAttributes();
  //     //                     that.set('flag', 1);
  //     //                     transition.retry();
  //     //                 }
  //     //             });
  //     //         }
  //     //     }
  //     //     that.set('flag', undefined);
  //     // }
  // });


  _exports.default = _default;
});