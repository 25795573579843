define("questmap/components/tables/unix", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    date: Ember.computed('value', function () {
      return !!this.get("value") ? moment(+this.value).format('DD.MM.YY') + ' в ' + moment(+this.value).format('HH:mm') : '';
    })
  });

  _exports.default = _default;
});