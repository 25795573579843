define("questmap/templates/components/bootstrap/form-textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YInYx7k2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n  \"],[7,\"label\",true],[10,\"class\",\"control-label\"],[8],[1,[22,\"label\"],false],[0,\" \\n\"],[4,\"if\",[[24,[\"maxWidth\"]]],null,{\"statements\":[[0,\"      \"],[1,[24,[\"value\",\"length\"]],false],[0,\"/\"],[1,[22,\"maxWidth\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\\n\"],[0,\"\\n  \"],[1,[28,\"textarea\",null,[[\"maxlength\",\"value\",\"class\",\"required\"],[[24,[\"maxWidth\"]],[24,[\"value\"]],\"form-control\",\"\"]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/components/bootstrap/form-textarea.hbs"
    }
  });

  _exports.default = _default;
});