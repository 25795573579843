define("questmap/templates/components/mp3-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vG7msRh1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\",false],[12,\"class\",\"closeAudio\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[0,\"✖\"],[9],[0,\"\\n\"],[7,\"audio\",true],[11,\"src\",[22,\"audioUrl\"]],[10,\"preload\",\"auto\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/components/mp3-player.hbs"
    }
  });

  _exports.default = _default;
});