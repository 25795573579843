define("questmap/routes/app/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),

    beforeModel() {
      if (this.currentUser.user.role !== 'Супер пользователь') this.transitionTo('index');
    },

    async setupController(controller) {
      controller.set('columns', [{
        label: 'Имя',
        valuePath: 'name'
      }, {
        label: 'Доступ',
        valuePath: 'access',
        cellComponent: 'tables/true-false'
      }, {
        label: 'Роль',
        valuePath: 'role'
      }, {
        label: 'Последний визит',
        valuePath: 'last_visit',
        cellComponent: 'tables/unix'
      }]);
    }

  });

  _exports.default = _default;
});