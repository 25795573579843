define("questmap/routes/app/partners/view/places/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return this.modelFor("app.partners.view").get('id');
    },

    async setupController(controller, model) {
      controller.set('columns', [{
        label: 'Название',
        valuePath: 'title',
        cellComponent: 'tables/link',
        linkPath: 'app.partners.view.places.view',
        linkId: 'id'
      }, {
        label: 'Скидка',
        valuePath: 'maxDiscount',
        align: 'center'
      }, {
        label: 'Открыт',
        valuePath: 'access',
        cellComponent: 'tables/true-false',
        align: 'center'
      }, {
        label: 'Запр',
        valuePath: 'orders',
        align: 'center'
      }, {
        label: 'Уник. запр',
        valuePath: 'uniqOrders',
        align: 'center'
      }, {
        label: 'ЧЖ',
        valuePath: 'blackPearlsCount',
        align: 'center'
      }, {
        label: 'БЖ',
        valuePath: 'whitePearlsCount',
        align: 'center'
      }, {
        label: 'Рейтинг',
        valuePath: 'delta',
        align: 'center'
      }, {
        label: 'Отзывов',
        valuePath: 'reviewsCount',
        align: 'center'
      }, {
        label: 'Новый отзыв',
        action: this.newReview,
        title: "Написать",
        cellComponent: 'tables/button'
      }, {
        label: 'Новая бронь',
        action: this.newOrder,
        title: "Забронировать",
        cellComponent: 'tables/button'
      }]);
      controller.set('constParams', {
        partner: model
      });
    },

    newReview(row) {
      let review = this.get("store").createRecord('place-review', {
        fake: true,
        accepted: true,
        place: row.content
      });
      this.modal.open('modals.place-review', review);
    },

    newOrder(row) {
      let order = this.get("store").createRecord('place-order', {
        place: row.content
      });
      this.modal.open('modals.place-order-new', order);
    }

  });

  _exports.default = _default;
});