define("questmap/models/profiledetail", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    org_type: attr('string'),
    full_title: attr('string'),
    director: attr('string'),
    address_real: attr('string'),
    address: attr('string'),
    phone: attr('string'),
    email: attr('string'),
    inn: attr('string'),
    ogrn: attr('string'),
    rs: attr('string'),
    ks: attr('string'),
    bic: attr('string'),
    bank: attr('string'),
    other: attr('string'),
    profile: attr('string')
  });

  _exports.default = _default;
});