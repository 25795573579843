define("questmap/templates/modals/place-order", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+lGBvRbq",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-window\",null,[[\"model\",\"size\"],[[24,[\"model\"]],\"lg\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"flexRow\"],[8],[0,\"\\n    \"],[7,\"h4\",true],[8],[0,\"Бронь в заведение \"],[1,[24,[\"model\",\"place\",\"title\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"p\",true],[10,\"class\",\"text-sm\"],[8],[0,\"\\n\\n    \"],[7,\"b\",true],[10,\"class\",\"pointer\"],[8],[0,\"\\n      Бронь от\\n      \"],[1,[24,[\"model\",\"human_date\"]],false],[0,\"\\n      \"],[1,[24,[\"model\",\"human_time\"]],false],[0,\"\\n    \"],[9],[0,\",    \\n\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"model\",\"review\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h4\",true],[8],[0,\"Отзыв \"],[1,[24,[\"model\",\"review\",\"delta\"]],false],[9],[0,\"\\n\\n    \"],[7,\"p\",true],[8],[1,[24,[\"model\",\"review\",\"description\"]],false],[9],[0,\"\\n\\n    \"],[7,\"button\",false],[3,\"action\",[[23,0,[]],[23,0,[\"modal\",\"open\"]],\"modals/place-review\",[24,[\"model\",\"review\"]]]],[8],[0,\"\\n      Посмотртеть\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/modals/place-order.hbs"
    }
  });

  _exports.default = _default;
});