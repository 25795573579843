define("questmap/models/playerpay", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    createdAt: (0, _model.attr)('number'),
    sum: (0, _model.attr)('number'),
    card: (0, _model.attr)('string'),
    paymentId: (0, _model.attr)('string'),
    fullname: (0, _model.attr)('string'),
    order: (0, _model.belongsTo)('order', {
      async: true
    }),
    profile: (0, _model.belongsTo)('profile', {
      async: true
    }),
    player: (0, _model.belongsTo)('player', {
      async: true
    })
  });

  _exports.default = _default;
});