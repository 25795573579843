define("questmap/controllers/modals/orderdetails", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    alerts: Ember.inject.service(),
    modal: Ember.inject.service(),
    constParams: Ember.computed('model.id', function () {
      return {
        order: this.model.id
      };
    }),
    columns: Ember.computed(function () {
      return [{
        label: '',
        valuePath: 'status',
        cellComponent: 'tables/orders/status',
        width: '10px',
        sortable: false
      }, {
        label: 'Статус',
        cellComponent: 'tables/orders/changed-status',
        sortable: false
      }, {
        label: 'Цена',
        cellComponent: 'tables/orders/changed-price',
        sortable: false
      }, {
        label: 'Организация',
        valuePath: 'profile_fee',
        cellComponent: 'tables/orders/fee',
        align: 'center',
        sortable: false
      }, {
        label: 'Игрок',
        valuePath: 'player_fee',
        cellComponent: 'tables/bonus',
        align: 'center',
        sortable: false
      }, {
        label: 'Дата создания',
        valuePath: 'createdAt',
        cellComponent: 'tables/table-date',
        sortable: false
      }, {
        label: 'Автор',
        cellComponent: 'tables/orders/author',
        sortable: false
      }];
    }),

    afterSave(status, model) {
      this.alerts.showAlert(status);
      model.reload();
    },

    actions: {
      async acceptOrder(model) {
        try {
          await this.get('ajax').request(`orders/acceptOrder?order=${model.id}&sum=${model.sum}`);
          this.afterSave("success", model);
          this.modal.close();
        } catch (err) {
          this.afterSave("error", model);
        }
      },

      async cancelOrder(model) {
        try {
          await this.get('ajax').request(`orders/cancelOrder?order=${model.id}`);
          this.afterSave("success", model);
          this.modal.close();
        } catch (err) {
          this.afterSave("error", model);
        }
      },

      async acceptOrderChanges(model) {
        try {
          await this.get('ajax').request('orders/acceptOrderChanges?order=' + model.id);
          this.afterSave("success", model);
          this.modal.close();
        } catch (err) {
          this.afterSave("error", model);
        }
      },

      async cancelToEmptyOrder(model) {
        try {
          await this.get('ajax').request(`orders/cancelToEmptyOrder?order=${model.id}&admin=${'admin'}`);
          this.afterSave("success", model);
          this.modal.close();
        } catch (err) {
          this.afterSave("error", model);
        }
      },

      async accpetedToEmptyOrder(model) {
        try {
          await this.get('ajax').request('orders/accpetedToEmptyOrder?order=' + model.id);
          this.afterSave("success", model);
          this.modal.close();
        } catch (err) {
          this.afterSave("error", model);
        }
      }

    }
  });

  _exports.default = _default;
});