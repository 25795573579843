define("questmap/services/modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    alerts: Ember.inject.service(),
    router: Ember.inject.service(),
    modal: Ember.inject.service(),
    crud: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    isOpenNow: false,
    refresh: false,
    model: null,

    async open(template, model, modelName, id, onlyForSuper) {
      this.set('modal.isOpenNow', true);
      if (onlyForSuper && this.get('currentUser.user.role') == 'Супер пользователь') return;
      if (modelName && !id) model = this.store.createRecord(modelName);
      if (modelName && id) model = await this.store.findRecord(modelName, id);
      if (!model) return; /////////////////////////////
      // let player = model.findRecord(modelName, 'agent');
      // console.log(player)
      // // player.get('agent').then((agent) => {
      // //   console.log(agent)
      // // });
      // console.log('ZZZZZZZZZZZZ', model)

      this.set('modal.isOpenNow', true);
      this.set('modal.model', model);
      Ember.getOwner(this).lookup(`route:application`).render(template, {
        into: 'application',
        outlet: 'modal',
        model: model
      });
    },

    close(path) {
      const that = this;
      if (!this.get("modal.isOpenNow")) return false;
      this.set('modal.refresh', true);
      this.set('modal.isOpenNow', false);
      this.set('modal.model', null);
      if (path) this.router.transitionTo(path);
      Ember.run.next(function () {
        that.set('modal.refresh', false);
        Ember.getOwner(that).lookup(`route:application`).disconnectOutlet({
          outlet: 'modal',
          parentView: 'application'
        });
      });
    },

    destroy(path) {
      if (!confirm('Вы уверены?')) return;
      let model = this.get('modal.model');

      if (model.store) {
        model.destroyRecord();
        const agent = model.agent;
        const agentClients = agent.get('clients');

        if (agentClients) {
          agent.set('clients', agentClients - 1);
          console.log('У агента удалился один пользователь');
        }

        this.alerts.showAlert("success", 'Данные удалены');
        this.modal.close(path);
      } else {
        const that = this;
        let store = this.get('store');
        store.findRecord('review', model.id).then(function (rec) {
          rec.destroyRecord();
          that.alerts.showAlert("success", 'Данные удалены');
          that.modal.close(path);
        }).catch(err => console.log(err));
      }
    },

    setAudio(audioUrl) {
      this.set('modal.audioUrl', audioUrl);
    },

    saveModal(ischecked, valueprofilename, type) {
      if (valueprofilename && valueprofilename.length === 0) {
        alert('Введите название франшизы!');
        return;
      }

      console.log('!!!', valueprofilename, ischecked, type);
      const that = this;
      let model = this.get('modal.model');
      let store = this.get('store');
      console.log(model, store);
      ischecked === true && (model.not_save = "true");
      valueprofilename && valueprofilename.length > 0 && (model.profile_name = valueprofilename); // console.log("notsave", model.not_save);
      // return;

      if (type === 'settings') {
        let setting = store.findRecord('setting', model.id).then(function (setting) {
          setting.set('title', model.title);
          setting.set('type', model.type);
          console.log(setting, 'setting');
          that.modal.close();
        });
        setting.save().then(data => console.log(data)).catch(err => console.log(err));
      }

      if (model.store) {
        return new Promise(function (resolve, reject) {
          model.save().then(() => {
            that.alerts.showAlert("success");
            that.modal.close();
            if (that.crud.modelForUpdate) that.crud.modelForUpdate.reload();
            resolve(true);
          }).catch(reason => {
            resolve(true);
            console.log(reason);
            that.alerts.showAlert("error");
          });
        });
      } else {
        store.findRecord('review', model.id).then(function (rec) {
          rec.set('player_name', model.player_name);
          rec.set('player_rank', model.player_rank);
          rec.set('createdDate', model.createdDate);
          rec.set('atmosphere', model.atmosphere);
          rec.set('clean', model.clean);
          rec.set('emotion', model.emotion);
          rec.set('mobility', model.mobility);
          rec.set('personal', model.personal);
          rec.set('puzzle', model.puzzle);
          rec.set('story', model.story);
          rec.set('player_orders', model.player_orders);
          rec.set('description', model.description);
          rec.set('hidden', model.hidden);
          rec.set('accepted', model.accepted);
          rec.save().then(data => console.log(data)).catch(err => console.log(err));
          that.alerts.showAlert("success", 'Данные изменены');
          that.modal.close();
        }).catch(err => console.log(err));
      }
    }

  });

  _exports.default = _default;
});