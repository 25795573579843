define("questmap/routes/app/agents/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller) {
      controller.set('columns', [{
        label: 'Имя',
        valuePath: 'name'
      }, {
        label: 'Фамилия',
        valuePath: 'surname'
      }, {
        label: 'Телефон',
        valuePath: 'phone'
      }, {
        label: 'Клиентов',
        valuePath: 'clients'
      }, {
        label: 'Броней',
        valuePath: 'orders_count'
      }, {
        label: 'Баланс',
        valuePath: 'balance',
        cellComponent: 'tables/table-balance'
      }, {
        label: 'Заходил',
        valuePath: 'last_visit',
        cellComponent: 'tables/unix',
        align: 'right',
        width: '120px'
      }]); //controller.send('search');
    }

  });

  _exports.default = _default;
});