define("questmap/models/review", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  const Validations = (0, _emberCpValidations.buildValidations)({
    description: (0, _emberCpValidations.validator)('cantblank'),
    player_name: (0, _emberCpValidations.validator)('cantblank'),
    player_rank: (0, _emberCpValidations.validator)('cantblank'),
    player_orders: (0, _emberCpValidations.validator)('cantblank'),
    atmosphere: (0, _emberCpValidations.validator)('cantblank'),
    clean: (0, _emberCpValidations.validator)('cantblank'),
    mobility: (0, _emberCpValidations.validator)('cantblank'),
    story: (0, _emberCpValidations.validator)('cantblank'),
    puzzle: (0, _emberCpValidations.validator)('cantblank'),
    personal: (0, _emberCpValidations.validator)('cantblank'),
    emotion: (0, _emberCpValidations.validator)('cantblank')
  });

  var _default = Model.extend(Validations, {
    description: attr('string'),
    audioUrl: attr('string'),
    emotion: attr('number'),
    delta: attr('number'),
    createdDate: attr('number'),
    atmosphere: attr('number'),
    clean: attr('number'),
    mobility: attr('number'),
    story: attr('number'),
    puzzle: attr('number'),
    personal: attr('number'),
    questroom: belongsTo('questroom', {
      async: true
    }),
    questroom_title: attr('string'),
    order: belongsTo('order', {
      async: true
    }),
    player: belongsTo('player', {
      async: true
    }),
    player_name: attr('string'),
    player_rank: attr('string'),
    player_orders: attr('string'),
    hidden: attr('boolean'),
    fake: attr('boolean'),
    accepted: attr('boolean')
  });

  _exports.default = _default;
});