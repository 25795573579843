define("questmap/components/forms/login", ["exports", "questmap/models/forms/login"], function (_exports, _login) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    alerts: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    model: Ember.computed(function () {
      return _login.default.create(Ember.getOwner(this).ownerInjection());
    }),
    actions: {
      submit(model) {
        const that = this;
        return new Promise(function (resolve, reject) {
          that.get('session').authenticate('authenticator:oauth2', model.identification, model.password, []).then(() => {
            that.router.transitionTo('index');
            resolve(true);
          }).catch(reason => {
            resolve(true);
            that.alerts.showAlert('error', reason && reason.message);
          });
        });
      }

    }
  });

  _exports.default = _default;
});