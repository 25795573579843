define("questmap/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONSerializer.extend({
    serializeAttribute(snapshot, json, key, attributes) {
      let c = snapshot.changedAttributes()[key];
      if (!!c || snapshot.record.get('isNew') && snapshot.record.get(key) || key === 'metro' || key === 'images') this._super(snapshot, json, key, attributes);
    },

    serialize(snapshot, options) {
      let json = this._super(...arguments);

      if (snapshot.id) json.id = snapshot.id;
      return json;
    }

  });

  _exports.default = _default;
});