define("questmap/routes/app/profiles/view/month", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return this.modelFor("app.profiles.view").get('id');
    },

    setupController(controller, model) {
      controller.set('columns', [{
        label: 'Месяц',
        valuePath: 'sid',
        cellComponent: 'tables/month'
      }, {
        label: 'Всего игр',
        valuePath: 'orders',
        align: 'center'
      }, {
        label: 'Состоялось',
        valuePath: 'accepted_orders',
        cellComponent: 'tables/table-green',
        align: 'center'
      }, {
        label: 'Отменено',
        valuePath: 'canceled_orders',
        cellComponent: 'tables/table-red',
        align: 'center'
      }, {
        label: 'Квест получил',
        valuePath: 'sum_to_pay',
        align: 'center'
      }, {
        label: 'Комиссионные',
        valuePath: 'fee',
        align: 'center'
      }]);
      controller.set('constParams', {
        profile: model
      });
    }

  });

  _exports.default = _default;
});