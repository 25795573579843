define("questmap/templates/components/tables/orders/cost", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "In2mr/Ce",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[8],[1,[28,\"elements/price\",null,[[\"value\"],[[24,[\"row\",\"sum\"]]]]],false],[0,\" р \\n\"],[4,\"if\",[[24,[\"row\",\"payed\"]]],null,{\"statements\":[[0,\"        / \"],[7,\"b\",true],[10,\"class\",\"red\"],[8],[1,[28,\"elements/price\",null,[[\"value\"],[[24,[\"row\",\"payed\"]]]]],false],[0,\" р\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"text-sm\"],[8],[1,[28,\"elements/price\",null,[[\"value\"],[[24,[\"row\",\"sum_to_pay\"]]]]],false],[0,\" р / \"],[1,[28,\"elements/price\",null,[[\"value\"],[[24,[\"row\",\"profile_fee\"]]]]],false],[0,\" р / \"],[1,[28,\"elements/price\",null,[[\"value\"],[[24,[\"row\",\"agent_fee\"]]]]],false],[0,\" р\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/components/tables/orders/cost.hbs"
    }
  });

  _exports.default = _default;
});