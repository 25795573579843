define("questmap/models/place-review", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    player_name: (0, _model.attr)('string'),
    player_rank: (0, _model.attr)('string'),
    createdDate: (0, _model.attr)('string'),
    order: (0, _model.belongsTo)('place-order', {
      async: true
    }),
    place: (0, _model.belongsTo)('place', {
      async: true
    }),
    partner: (0, _model.belongsTo)('partner', {
      async: true
    }),
    fake: (0, _model.attr)('boolean'),
    hidden: (0, _model.attr)('boolean'),
    accepted: (0, _model.attr)('boolean'),
    blackPearl: (0, _model.attr)('boolean'),
    whitePearl: (0, _model.attr)('boolean'),
    discount: (0, _model.attr)('boolean'),
    player_orders: (0, _model.attr)('number'),
    service: (0, _model.attr)('number'),
    clean: (0, _model.attr)('number'),
    price: (0, _model.attr)('number'),
    emotion: (0, _model.attr)('number'),
    atmosphere: (0, _model.attr)('number'),
    delta: (0, _model.attr)('number'),
    place_title: (0, _model.attr)('string'),
    description: (0, _model.attr)('string')
  });

  _exports.default = _default;
});