define("questmap/routes/app/partners/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    alerts: Ember.inject.service(),

    setupController(controller) {
      controller.set('columns', [{
        label: 'ID',
        valuePath: 'uid'
      }, {
        label: 'Название',
        valuePath: 'title'
      }, {
        label: 'Заведения',
        valuePath: 'places.length',
        align: "center"
      }, {
        label: 'Скидка',
        valuePath: 'maxDiscount',
        align: "center"
      }, {
        label: 'Запр',
        valuePath: 'orders',
        align: 'center'
      }, {
        label: 'Уник. запр',
        valuePath: 'uniqOrders',
        align: 'center'
      }, {
        label: 'ЧЖ',
        valuePath: 'blackPearlsCount',
        align: 'center'
      }, {
        label: 'БЖ',
        valuePath: 'whitePearlsCount',
        align: 'center'
      }, {
        label: 'Виджет',
        valuePath: 'setupWidget',
        cellComponent: 'tables/true-false',
        align: 'center'
      }, {
        label: 'Отключен',
        valuePath: 'deactivated',
        cellComponent: 'tables/true-false',
        align: 'center'
      }, {
        label: 'Редактирование',
        valuePath: 'allowEdit',
        cellComponent: 'tables/true-false',
        align: 'center'
      }, {
        label: 'Заходил',
        valuePath: 'last_visit',
        cellComponent: 'tables/unix',
        align: 'right'
      }]);
    },

    actions: {
      async closeEditQuestrooms() {
        try {
          await this.get('ajax').request('/partners/closeAccessToEdit');
          this.alerts.showAlert('success', 'Редактирование закрыто');
        } catch (err) {
          this.alerts.showAlert('error', 'Ошибка');
        }
      }

    }
  });

  _exports.default = _default;
});