define("questmap/routes/app/agents/view/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return this.modelFor("app.agents.view").get('id');
    },

    async setupController(controller, model) {
      controller.set('columns', [{
        label: '',
        valuePath: 'log_status',
        cellComponent: 'tables/orders/status',
        width: '10px'
      }, {
        label: 'Квест',
        valuePath: 'questroom_title',
        cellComponent: 'tables/orders/title'
      }, {
        label: 'Дата',
        valuePath: 'date',
        cellComponent: 'tables/orders/date'
      }, {
        label: 'Клиент',
        valuePath: 'phone',
        cellComponent: 'tables/orders/player',
        width: '200px'
      }, {
        label: 'Сумма игры',
        valuePath: 'sum',
        cellComponent: 'tables/orders/sum'
      }, {
        label: 'К оплате',
        valuePath: 'sum_to_pay',
        cellComponent: 'tables/orders/sum'
      }, {
        label: 'Агентские',
        valuePath: 'agent_fee',
        cellComponent: 'tables/orders/fee'
      }, {
        label: 'Посетил',
        valuePath: 'status',
        cellComponent: 'tables/table-accept'
      }, {
        label: 'Оплата',
        valuePath: 'online_payment',
        width: '30px'
      }]);
      controller.set('constParams', {
        agent: model
      });
    }

  });

  _exports.default = _default;
});