define("questmap/routes/app/partners/view/places/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let m = this.modelFor("app.partners.view");
      return this.store.createRecord("place", {
        partner: m
      });
    },

    async setupController(controller, model) {
      const categories = this.store.findAll("place-category");
      controller.set('model', model);
      controller.set('categories', categories);
    }

  });

  _exports.default = _default;
});