define("questmap/templates/app/meta/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HhTScHWf",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"content-title\",null,[[\"path\",\"modelName\",\"title\",\"icon\"],[\"modals/meta\",\"meta\",\"Микроразметка\",\"plus\"]]],false],[0,\"\\n\\n\"],[4,\"unless\",[[24,[\"modal\",\"refresh\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"tables/table-index\",null,[[\"columns\",\"height\",\"modalPath\",\"modelName\"],[[24,[\"model\",\"columns\"]],\"65vh\",\"modals/meta\",\"meta\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/app/meta/index.hbs"
    }
  });

  _exports.default = _default;
});