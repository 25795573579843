define("questmap/templates/app/profiles/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nWaytQ09",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"content-title\",null,[[\"path\",\"model\",\"title\",\"icon\"],[\"modals/organization\",[24,[\"model\"]],[24,[\"model\",\"title\"]],\"pencil\"]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"viewNavWrap\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[8],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"route\"],[\"app.profiles.view.index\"]],{\"statements\":[[0,\"Данные\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"route\"],[\"app.profiles.view.users\"]],{\"statements\":[[0,\"Пользователи\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"route\"],[\"app.profiles.view.questrooms\"]],{\"statements\":[[0,\"Квесты\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"route\"],[\"app.profiles.view.orders\"]],{\"statements\":[[0,\"Записи (\"],[1,[24,[\"model\",\"orders_count\"]],false],[0,\")\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"route\"],[\"app.profiles.view.payments\"]],{\"statements\":[[0,\"Платежи\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"route\"],[\"app.profiles.view.adjustments\"]],{\"statements\":[[0,\"Корректировки\"]],\"parameters\":[]},null],[0,\"\\n\"],[0,\"\\t\\t\"],[4,\"link-to\",null,[[\"route\"],[\"app.profiles.view.statistic\"]],{\"statements\":[[0,\"Статистика\"]],\"parameters\":[]},null],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[8],[0,\"\\n\\t\\t\"],[7,\"button\",false],[3,\"action\",[[23,0,[]],[23,0,[\"adjustments\",\"create\"]],\"profileadjustment\",\"profile\",[24,[\"model\"]]]],[8],[0,\" \\n\\t\\t\\tБаланс \"],[1,[28,\"elements/price\",null,[[\"value\"],[[24,[\"model\",\"balance\"]]]]],false],[0,\" р\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/app/profiles/view.hbs"
    }
  });

  _exports.default = _default;
});