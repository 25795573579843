define("questmap/routes/app/profiles/view/questrooms/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return this.modelFor("app.profiles.view").get('id');
    },

    async setupController(controller, model) {
      controller.set('columns', [{
        label: 'Название',
        valuePath: 'title',
        cellComponent: 'tables/link',
        linkPath: 'app.profiles.view.questrooms.edit',
        linkId: 'id'
      }, {
        label: 'Запись открыта',
        valuePath: 'access',
        cellComponent: 'tables/true-false',
        width: '160px',
        align: 'center'
      }, {
        label: 'Кол-во игр',
        valuePath: 'orders_count',
        width: '160px',
        align: 'center'
      }, {
        label: 'Рейтинг',
        valuePath: 'delta',
        width: '160px',
        align: 'center'
      }, {
        label: 'Кол-во отзывов',
        valuePath: 'reviews_count',
        width: '160px',
        align: 'center'
      }, {
        label: 'Новый отзыв',
        action: this.newReview,
        title: "Написать",
        cellComponent: 'tables/button'
      }]);
      controller.set('constParams', {
        profile: model
      });
    },

    newReview(row) {
      let review = this.get("store").createRecord('review', {
        fake: true,
        accepted: true,
        questroom: row.content
      });
      this.modal.open('modals.review', review);
    }

  });

  _exports.default = _default;
});