define("questmap/components/tables/month", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    date: Ember.computed('value', function () {
      return moment.utc(this.get('value')).locale("ru").format('MMMM YYYY');
    })
  });

  _exports.default = _default;
});