define("questmap/templates/components/forms/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W3+5cBMB",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[5,\"bs-form\",[],[[\"@model\",\"@onSubmit\"],[[22,\"model\"],[28,\"action\",[[23,0,[]],\"submit\"],null]]],{\"statements\":[[0,\"\\n\\t\"],[6,[23,1,[\"element\"]],[],[[\"@controlType\",\"@label\",\"@property\"],[\"text\",\"Email\",\"identification\"]]],[0,\"\\n\\t\"],[5,\"bs-button\",[],[[\"@defaultText\",\"@buttonType\",\"@type\",\"@disabled\"],[\"Сбросить\",\"submit\",\"primary\",[23,1,[\"isSubmitting\"]]]]],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/components/forms/reset-password.hbs"
    }
  });

  _exports.default = _default;
});