define("questmap/components/upload-image", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    get
  } = Ember;

  var _default = Ember.Component.extend({
    loading: false,
    ajax: Ember.inject.service(),
    uploadPhoto: (0, _emberConcurrency.task)(function* (file) {
      const that = this;
      that.set('loading', true);

      try {
        file.readAsDataURL().then(function (url) {
          that.get('ajax').request('images/upload', {
            method: 'POST',
            data: {
              img: url,
              type: that.type
            }
          }).then(r => {
            that.set('model.image', r.img);
            if (that.type == "questrooms") that.get('model.images').pushObject(r.img);
            that.set('loading', false);
          }).catch(err => {
            console.log(err);
            that.get('alerts').showAlert('error', 'Image too big :(');
          });
        });
      } catch (e) {
        console.log(e);
        that.get('alerts').showAlert('error', 'Image too big :(');
      }
    }).maxConcurrency(3).enqueue(),
    actions: {
      uploadImage(file) {
        get(this, 'uploadPhoto').perform(file);
      }

    }
  });

  _exports.default = _default;
});