define("questmap/components/elements/model-select-multiple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-group'],
    store: Ember.inject.service(),

    async didInsertElement() {
      let options = this.get('store').findAll(this.modelName);
      this.set('options', options);
    }

  });

  _exports.default = _default;
});