define("questmap/routes/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    beforeModel: function (transition) {
      if (this.get('session.data.authenticated.access_token')) {
        this.transitionTo('app.profiles.index');
      } else {
        this.transitionTo('login');
      }
    }
  });

  _exports.default = _default;
});