define("questmap/templates/components/elements/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VgibpTCK",
    "block": "{\"symbols\":[\"dropdown\",\"calendar\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"formElement\"],[8],[0,\"\\n    \"],[5,\"basic-dropdown\",[],[[],[]],{\"statements\":[[0,\"\\n        \"],[7,\"input\",true],[11,\"data-ebd-id\",[29,[[23,1,[\"uniqueId\"]],\"-trigger\"]]],[10,\"class\",\"datepicker-demo-input\"],[11,\"onclick\",[23,1,[\"actions\",\"toggle\"]]],[11,\"value\",[22,\"formatDate\"]],[10,\"readonly\",\"\"],[10,\"type\",\"text\"],[8],[9],[0,\"\\n\\n        \"],[6,[23,1,[\"Content\"]],[[12,\"class\",\"datepicker-demo-dropdown\"]],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"power-calendar\",null,[[\"center\",\"selected\",\"onCenterChange\",\"onSelect\"],[[24,[\"center\"]],[24,[\"date\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"center\"]]],null]],[[\"value\"],[\"date\"]]],[28,\"action\",[[23,0,[]],\"changeDate\"],null]]],{\"statements\":[[0,\"                \"],[1,[23,2,[\"Nav\"]],false],[0,\"\\n                \"],[1,[23,2,[\"Days\"]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"        \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"label\"],[8],[7,\"i\",true],[10,\"class\",\"fa fa-calendar\"],[10,\"aria-hidden\",\"true\"],[8],[9],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/components/elements/calendar.hbs"
    }
  });

  _exports.default = _default;
});