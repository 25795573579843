define("questmap/templates/app/players/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6uR6yqwK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row ml-1\"],[8],[0,\"\\n\\t\"],[1,[28,\"content-title\",null,[[\"path\",\"modelName\",\"title\",\"icon\",\"notification\"],[\"modals/clientnotsave\",\"player\",\"Клиенты\",\"plus\",\"true\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"tables/table-index\",null,[[\"columns\",\"height\",\"link\",\"modelName\"],[[24,[\"columns\"]],\"80vh\",\"app.players.view.index\",\"player\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/app/players/index.hbs"
    }
  });

  _exports.default = _default;
});