define("questmap/routes/app/players/view/reviews", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return this.modelFor("app.players.view").get('id');
    },

    async setupController(controller, model) {
      controller.set('columns', [{
        label: 'Игрок',
        valuePath: 'player_name',
        cellComponent: 'tables/reviews/player',
        width: "200px"
      }, {
        label: 'Дата',
        valuePath: 'createdDate',
        cellComponent: 'tables/unix'
      }, {
        label: 'Оценка',
        valuePath: 'delta',
        cellComponent: 'tables/reviews/questroom'
      }, {
        label: 'Отзыв',
        valuePath: 'description',
        cellComponent: 'tables/reviews/review'
      }, {
        label: 'Аудио',
        valuePath: 'audioUrl',
        cellComponent: 'tables/true-false',
        align: "center",
        width: "100px"
      }, {
        label: 'Одобр',
        valuePath: 'accepted',
        cellComponent: 'tables/true-false',
        align: "center",
        width: "100px"
      }]);
      controller.set('constParams', {
        player: model
      });
    }

  });

  _exports.default = _default;
});