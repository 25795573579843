define("questmap/templates/modals/adjustment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cXSabIhw",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[4,\"modal-window\",null,[[\"model\"],[[24,[\"model\"]]]],{\"statements\":[[0,\"\\t\"],[5,\"bs-form\",[],[[\"@model\",\"@onSubmit\"],[[22,\"model\"],[28,\"action\",[[23,0,[]],[23,0,[\"modal\",\"saveModal\"]]],null]]],{\"statements\":[[0,\"\\n\\t\\t\"],[6,[23,1,[\"element\"]],[],[[\"@controlType\",\"@label\",\"@property\"],[\"text\",\"Причина\",\"comment\"]]],[0,\"\\n\\t\\t\"],[6,[23,1,[\"element\"]],[],[[\"@controlType\",\"@label\",\"@property\"],[\"number\",\"Сумма начисления\",\"sum\"]]],[0,\"\\n\\t\\t\"],[5,\"bs-button\",[],[[\"@defaultText\",\"@buttonType\",\"@type\",\"@disabled\"],[\"Сохранить\",\"submit\",\"primary\",[23,1,[\"isSubmitting\"]]]]],[0,\"\\n\\t\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/modals/adjustment.hbs"
    }
  });

  _exports.default = _default;
});