define("questmap/templates/components/tables/player/contacts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G0PTc8DU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[8],[1,[24,[\"row\",\"phone\"]],false],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"text-sm\"],[8],[1,[24,[\"row\",\"email\"]],false],[9],[0,\" \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/components/tables/player/contacts.hbs"
    }
  });

  _exports.default = _default;
});