define("questmap/templates/app/profiles/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Adq0Ifjw",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"content-title\",null,[[\"path\",\"modelName\",\"title\",\"icon\"],[\"modals/organization\",\"profile\",\"Организации\",\"plus\"]]],false],[0,\"\\n\\n\"],[4,\"unless\",[[24,[\"modal\",\"refresh\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"tables/table-index\",null,[[\"columns\",\"height\",\"link\",\"modelName\"],[[24,[\"columns\"]],\"65vh\",\"app.profiles.view.index\",\"profile\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"button\",false],[12,\"class\",\"btn btn-all\"],[3,\"action\",[[23,0,[]],\"closeEditQuestrooms\"]],[8],[0,\" Закрыть редактирование квестов\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/app/profiles/index.hbs"
    }
  });

  _exports.default = _default;
});