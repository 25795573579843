define("questmap/routes/app/profiles/view/statistic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),

    model() {
      return this.modelFor("app.profiles.view").get('id');
    },

    actions: {
      async getStatistic(profile, range) {
        if (!range || !range.start || !range.end) return alert("Укажите интервал");
        let start = moment(range.start).format('YYYY-MM-DD');
        let end = moment(range.end).format('YYYY-MM-DD');
        const data = await this.get("ajax").request(`/profiles/statistic?profile=${profile}&start=${start}&end=${end}`);
        this.controllerFor('app.profiles.view.statistic').setProperties({
          statistic: data.statistic,
          questrooms: data.questroomsObject
        });
      }

    }
  });

  _exports.default = _default;
});