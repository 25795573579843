define("questmap/helpers/get-reviews", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function getReviews(params
  /*, hash*/
  ) {
    console.log(params);
    params = []; // return params;
  });

  _exports.default = _default;
});