define("questmap/templates/components/tables/has-many", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QnNLFpyA",
    "block": "{\"symbols\":[\"model\"],\"statements\":[[4,\"each\",[[24,[\"value\"]]],null,{\"statements\":[[0,\" \"],[1,[23,1,[\"title\"]],false],[0,\",\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/components/tables/has-many.hbs"
    }
  });

  _exports.default = _default;
});