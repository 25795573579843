define("questmap/templates/components/tables/player/info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fTjsaE2H",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[8],[1,[24,[\"row\",\"name\"]],false],[0,\" \"],[1,[24,[\"row\",\"surname\"]],false],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"text-sm\"],[8],[4,\"if\",[[24,[\"row\",\"not_save\"]]],null,{\"statements\":[[0,\" \"],[1,[24,[\"row\",\"profile_name\"]],false],[0,\" \"]],\"parameters\":[]},{\"statements\":[[0,\" \"],[1,[24,[\"row\",\"rank\",\"title\"]],false],[0,\", \"],[1,[24,[\"row\",\"rank\",\"discount\"]],false],[0,\" % \"]],\"parameters\":[]}],[0,\" \"],[9],[0,\" \\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/components/tables/player/info.hbs"
    }
  });

  _exports.default = _default;
});