define("questmap/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BJOeeRhz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\",true],[8],[0,\"meta\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/index.hbs"
    }
  });

  _exports.default = _default;
});