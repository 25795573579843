define("questmap/templates/app/reviews", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xHgul/+m",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"content-title\",null,[[\"title\"],[\"Отзывы\"]]],false],[0,\"\\n\\n\"],[1,[28,\"filters/reviews\",null,[[\"searchParams\",\"range\"],[[24,[\"searchParams\"]],[24,[\"range\"]]]]],false],[0,\"\\n\\n\"],[1,[28,\"tables/table-index\",null,[[\"columns\",\"searchParams\",\"modalPath\",\"height\",\"modelName\"],[[24,[\"columns\"]],[24,[\"searchParams\"]],\"modals/review\",\"60vh\",\"review\"]]],false],[0,\"\\n\\n\"],[7,\"button\",false],[12,\"class\",\"btn btn-all\"],[3,\"action\",[[23,0,[]],\"updateReviews\"]],[8],[0,\"Обновить отзывы\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/app/reviews.hbs"
    }
  });

  _exports.default = _default;
});