define("questmap/templates/components/tables/unix", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UNY204XN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[8],[1,[22,\"date\"],false],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"text-sm\"],[8],[1,[24,[\"row\",\"last_device\"]],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/components/tables/unix.hbs"
    }
  });

  _exports.default = _default;
});