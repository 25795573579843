define("questmap/components/tables/orders/changed-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    val: Ember.computed('row.old_sum', 'row.new_sum', function () {
      let {
        old_sum,
        new_sum
      } = this.get('row').getProperties('old_sum', 'new_sum');
      return old_sum && old_sum !== new_sum ? numberWithSpaces(old_sum) + ' -> ' + numberWithSpaces(new_sum) : numberWithSpaces(new_sum);
    })
  });

  _exports.default = _default;

  function numberWithSpaces(x) {
    x = x || 0;
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' р';
  }
});