define("questmap/routes/app/profiles/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    alerts: Ember.inject.service(),

    setupController(controller) {
      controller.set('columns', [{
        label: 'ID',
        valuePath: 'sid',
        width: '30px'
      }, {
        label: 'Название',
        valuePath: 'title',
        width: '250px'
      }, {
        label: 'Город',
        valuePath: 'cityFront',
        width: '180px'
      }, {
        label: 'Квестов',
        valuePath: 'questrooms_count',
        align: "center"
      }, {
        label: 'Кред. лим',
        valuePath: 'credit_limit',
        cellComponent: 'tables/orders/fee'
      }, {
        label: 'Баланс',
        valuePath: 'balance',
        cellComponent: 'tables/table-balance'
      }, {
        label: 'Отключен',
        valuePath: 'isLimitOver',
        cellComponent: 'tables/true-false',
        align: "center"
      }, {
        label: 'Запросы',
        valuePath: 'requests_сount',
        align: "center"
      }, {
        label: 'Заходил',
        valuePath: 'last_visit',
        cellComponent: 'tables/unix',
        align: 'right',
        width: '120px'
      }]); //controller.send('search');
    },

    actions: {
      async closeEditQuestrooms() {
        try {
          await this.get('ajax').request('/profiles/closeAccessToEdit');
          this.alerts.showAlert('success', 'Редактирование закрыто');
        } catch (err) {
          this.alerts.showAlert('error', 'Ошибка');
        }
      }

    }
  });

  _exports.default = _default;
});