define("questmap/templates/components/content-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ti6guJrU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\",true],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"icon\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",false],[3,\"action\",[[23,0,[]],\"new\"]],[8],[0,\"\\n        \"],[7,\"i\",true],[11,\"class\",[29,[\"fa fa-\",[22,\"icon\"]]]],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"notification\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"count-notifications\"],[8],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"count-notifications_text\"],[8],[0,\"Количество клиентов с подключенными уведомлениями\"],[9],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"count-notifications_numbers\"],[8],[1,[22,\"countNotification\"],false],[0,\" из \"],[1,[22,\"allPlayers\"],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/components/content-title.hbs"
    }
  });

  _exports.default = _default;
});