define("questmap/components/agents/agent-form", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    get,
    set
  } = Ember;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),
    alerts: Ember.inject.service(),
    ajax: Ember.inject.service(),
    uploadPhoto: (0, _emberConcurrency.task)(function* (file) {
      const that = this;

      try {
        file.readAsDataURL().then(function (url) {
          let photo = that.get('ajax').request('questrooms/uploadPhoto', {
            method: 'POST',
            data: {
              img: url
            }
          }).then(r => {
            that.set('model.photo', r.img);
          }).catch(err => {
            that.get('alerts').showAlert('error', 'Image too big :(');
          });
        });
      } catch (e) {
        photo.rollback();
      }
    }).maxConcurrency(3).enqueue(),
    actions: {
      uploadImage(file) {
        get(this, 'uploadPhoto').perform(file);
      },

      save(model) {
        this.crud.save(model, 'agents.index');
      },

      destroy(model) {
        this.crud.destroy(model, 'agents.index');
      } // createPayment(model) {
      // 	let inflow = this.store.createRecord('agentPay', {
      // 	    sum: 0,
      // 	    agent: model, 
      // 	});
      // 	this.sendAction('showModal', 'modals/add-inflow', inflow);
      // }


    }
  });

  _exports.default = _default;
});