define("questmap/initializers/current-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'currentUser',
    initialize: function (app) {
      app.inject('component', 'currentUser', 'service:currentUser');
      app.inject('controller', 'currentUser', 'service:currentUser');
      app.inject('route', 'currentUser', 'service:currentUser');
    }
  };
  _exports.default = _default;
});