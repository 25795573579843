define("questmap/models/profile", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: (0, _emberCpValidations.validator)('cantblank'),
    email: (0, _emberCpValidations.validator)('cantblank'),
    address: (0, _emberCpValidations.validator)('cantblank'),
    phone: (0, _emberCpValidations.validator)('cantblank')
  });

  var _default = _model.default.extend(Validations, {
    title: (0, _model.attr)('string'),
    group_title: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    phone: (0, _model.attr)('string'),
    contacts: (0, _model.attr)('string'),
    comment: (0, _model.attr)('string'),
    address: (0, _model.attr)('string'),
    site: (0, _model.attr)('string'),
    sid: (0, _model.attr)('string'),
    credit_limit: (0, _model.attr)('number'),
    balance: (0, _model.attr)('number'),
    balance_sms: (0, _model.attr)('number'),
    canEdit: (0, _model.attr)('boolean'),
    isLimitOver: (0, _model.attr)('boolean'),
    last_device: (0, _model.attr)('string'),
    last_visit: (0, _model.attr)('number'),
    requests_сount: (0, _model.attr)('number'),
    questrooms_count: (0, _model.attr)('number'),
    orders_count: (0, _model.attr)('number'),
    city: (0, _model.attr)('string'),
    cityFront: Ember.computed('city', function () {
      if (this.city === undefined) {
        return 'Москва';
      }

      return `${this.city}`;
    }),
    citySelect: Ember.A(['Москва', 'Санкт-Петербург'])
  });

  _exports.default = _default;
});