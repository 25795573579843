define("questmap/components/modal-window", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),

    init() {
      this._super(...arguments);

      console.log('this.model', this);
    },

    actions: {
      close() {
        if (this.model) {
          if (this.model.hasDirtyAttributes && !confirm('Изменения не сохранены! Вы уверены что хотите выйти?')) return false;
          this.model.rollbackAttributes && this.model.rollbackAttributes();
        }

        this.set('isOpen', false);
      }

    }
  });

  _exports.default = _default;
});