define("questmap/templates/components/tables/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gng1cGEe",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\",false],[3,\"action\",[[23,0,[]],[24,[\"column\",\"action\"]],[24,[\"row\"]]]],[8],[1,[24,[\"column\",\"title\"]],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/components/tables/button.hbs"
    }
  });

  _exports.default = _default;
});