define("questmap/templates/app/payments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "08KQVIbJ",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"content-title\",null,[[\"title\"],[\"Платежи\"]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"viewNavWrap\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[8],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"route\"],[\"app.payments.index\"]],{\"statements\":[[0,\"Платежи организаций\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"route\"],[\"app.payments.players\"]],{\"statements\":[[0,\"Платежи игроков\"]],\"parameters\":[]},null],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/app/payments.hbs"
    }
  });

  _exports.default = _default;
});