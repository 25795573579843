define("questmap/templates/app/players/view/reviews", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Kd0ym35z",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"filters/reviews\",null,[[\"searchParams\",\"withoutStartRange\",\"hideReal\"],[[24,[\"searchParams\"]],true,true]]],false],[0,\"\\n\\n\"],[1,[28,\"tables/table-index\",null,[[\"columns\",\"searchParams\",\"constParams\",\"modalPath\",\"height\",\"modelName\"],[[24,[\"columns\"]],[24,[\"searchParams\"]],[24,[\"constParams\"]],\"modals/review\",\"60vh\",\"review\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/app/players/view/reviews.hbs"
    }
  });

  _exports.default = _default;
});