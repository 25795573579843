define("questmap/templates/app/settings/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MwSc3YYr",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"content-title\",null,[[\"path\",\"modelName\",\"title\",\"icon\"],[\"modals/settings\",\"setting\",\"Настройки\",\"plus\"]]],false],[0,\"\\n\\n\\n\"],[1,[28,\"tables/table-index\",null,[[\"columns\",\"hideSearch\",\"height\",\"modalPath\",\"modelName\"],[[24,[\"columns\"]],true,\"70vh\",\"modals/settings\",\"setting\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/app/settings/index.hbs"
    }
  });

  _exports.default = _default;
});