define("questmap/models/orderlog", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    createdAt: (0, _model.attr)('number'),
    sid: (0, _model.attr)('string'),
    questroom_title: (0, _model.attr)('string'),
    profile: (0, _model.attr)('string'),
    order: (0, _model.attr)('string'),
    order_date: (0, _model.attr)('string'),
    player: (0, _model.attr)('string'),
    author: (0, _model.attr)('string'),
    author_name: (0, _model.attr)('string'),
    old_sum: (0, _model.attr)('number'),
    new_sum: (0, _model.attr)('number'),
    profile_fee: (0, _model.attr)('number'),
    player_fee: (0, _model.attr)('number'),
    agent_fee: (0, _model.attr)('number'),
    status: (0, _model.attr)('string'),
    new_status: (0, _model.attr)('string'),
    old_status: (0, _model.attr)('string'),
    profile_balance: (0, _model.attr)('number'),
    player_balance: (0, _model.attr)('number'),
    agent_balance: (0, _model.attr)('number'),
    human_time: Ember.computed('order_date', function () {
      return moment.utc(this.order_date).format('HH:mm');
    }),
    human_date: Ember.computed('order_date', function () {
      return moment.utc(this.order_date).format('DD.MM.YY');
    })
  });

  _exports.default = _default;
});