define("questmap/templates/components/bootstrap/form-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Cg/Nq+2T",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n  \"],[7,\"label\",true],[8],[1,[22,\"label\"],false],[9],[0,\"\\n  \"],[1,[28,\"input\",null,[[\"value\",\"type\",\"class\"],[[24,[\"value\"]],\"password\",\"form-control\"]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/components/bootstrap/form-password.hbs"
    }
  });

  _exports.default = _default;
});