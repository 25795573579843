define("questmap/models/place-category", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    title: (0, _model.attr)('string', {
      required: true
    }),
    imageUrl: (0, _model.attr)('string', {
      required: true
    }),
    types: (0, _model.hasMany)('place-type', {
      async: true
    }),
    placesCount: (0, _model.attr)('number')
  });

  _exports.default = _default;
});