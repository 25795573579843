define("questmap/components/header-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "header",
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    modal: Ember.inject.service(),
    actions: {
      invalidateSession() {
        if (!confirm("Вы уверены что хотите выйти?")) return false;
        this.get('session').invalidate();
        this.sendAction('redirect', 'login');
      }

    }
  });

  _exports.default = _default;
});