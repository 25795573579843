define("questmap/components/filters/orders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),

    didInsertElement() {
      if (this.withoutStartRange) return this.getSearchParams();
      this.set('range', {
        start: moment().subtract(14, 'days').toDate(),
        end: moment().toDate()
      });
    },

    statuses: Ember.A([{
      id: "empty",
      title: "Не проставлено"
    }, {
      id: "accepted",
      title: "Состоялось"
    }, {
      id: "canceled",
      title: "Отменено"
    }]),
    checking: Ember.A([{
      id: "success",
      title: "Согласовано"
    }, {
      id: "warning",
      title: "Предупреждение"
    }, {
      id: "error",
      title: "Требует внимания"
    }]),
    search: Ember.observer('status.id', 'questroom.id', 'log_status.id', 'range.start', 'range.end', function () {
      this.getSearchParams();
    }),

    getSearchParams() {
      let searchParams = {};

      if (this.range && this.range.start && this.range.end) {
        searchParams.date = {
          ">=": moment(this.range.start).format(),
          "<=": moment(this.range.end).endOf('day').format()
        };
      }

      if (this.log_status) searchParams.log_status = this.log_status.id;
      if (this.status) searchParams.status = this.status.id;
      if (this.questroom) searchParams.questroom = this.questroom.id;
      this.set("searchParams", searchParams);
      if (this.constParams) searchParams = Object.assign(searchParams, this.constParams);
      this.get('ajax').request('/orders/getStatistic?' + jQuery.param(searchParams)).then(s => {
        this.set('statistic', s);
      });
    }

  });

  _exports.default = _default;
});