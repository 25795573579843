define("questmap/templates/components/tables/table-balance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/QgQxMvH",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[11,\"class\",[29,[[22,\"state\"]]]],[8],[1,[28,\"elements/price\",null,[[\"value\"],[[24,[\"value\"]]]]],false],[0,\" р\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/components/tables/table-balance.hbs"
    }
  });

  _exports.default = _default;
});