define("questmap/templates/app/profiles/view/questrooms/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Lp6wOPL9",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\"],[\"button\",\"app.profiles.view.questrooms.new\"]],{\"statements\":[[0,\"    Новый квест \\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"tables/table-index\",null,[[\"columns\",\"height\",\"constParams\",\"modelName\"],[[24,[\"columns\"]],\"80vh\",[24,[\"constParams\"]],\"questroom\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/app/profiles/view/questrooms/index.hbs"
    }
  });

  _exports.default = _default;
});