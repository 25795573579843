define("questmap/components/filters/reviews", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    classNames: ["visiting-nav"],

    didInsertElement() {
      this.getSearchParams();
    },

    fake: "Только реальные",
    hidden: "Скрытые и активные",
    accepted: "Все статусы",
    fakes: Ember.A(["Только реальные", "Подставные", "Реальные и подставные"]),
    hiddens: Ember.A(["Скрытые", "Активные", "Скрытые и активные"]),
    accepteds: Ember.A(["Подтвержденные", "Новые", "Все статусы"]),
    search: Ember.observer('fake', 'hidden', 'accepted', 'range.start', 'range.end', function () {
      this.getSearchParams();
    }),

    getSearchParams() {
      let searchParams = {};

      if (this.range && this.range.start && this.range.end) {
        searchParams.createdAt = {
          ">=": moment(this.range.start).valueOf(),
          "<=": moment(this.range.end).endOf('day').valueOf()
        };
      }

      if (this.fake == "Только реальные") searchParams.fake = false;
      if (this.fake == "Подставные") searchParams.fake = true;
      if (this.hidden == "Скрытые") searchParams.hidden = true;
      if (this.hidden == "Активные") searchParams.hidden = false;
      if (this.accepted == "Подтвержденные") searchParams.accepted = true;
      if (this.accepted == "Новые") searchParams.accepted = false;
      this.set("searchParams", searchParams);
    }

  });

  _exports.default = _default;
});