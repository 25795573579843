define("questmap/controllers/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    actions: {
      authenticate() {
        const that = this;
        let {
          email,
          password
        } = this.getProperties('email', 'password');
        that.get('session').authenticate('authenticator:oauth2', email, password).then(() => {
          that.transitionToRoute('profiles.index');
        }).catch(reason => {
          that.set('errorMessage', reason.err || reason.message || reason.details || reason);
        });
      }

    }
  });

  _exports.default = _default;
});