define("questmap/components/tables/bonus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    val: Ember.computed('value', function () {
      return numberWithSpaces(this.value);
    })
  });

  _exports.default = _default;

  function numberWithSpaces(x) {
    if (!x) return '-';
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' б';
  }
});