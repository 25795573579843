define("questmap/models/meta", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    url: _emberData.default.attr('string', {
      defaultValue: "/"
    }),
    title: _emberData.default.attr('string', {
      defaultValue: "Карта Капитана"
    }),
    description: _emberData.default.attr('string', {
      defaultValue: "Карта Капитана - больше чем квесты"
    }),
    og_url: _emberData.default.attr('string', {
      defaultValue: "https://capcard.ru"
    }),
    og_type: _emberData.default.attr('string', {
      defaultValue: "website"
    }),
    og_title: _emberData.default.attr('string', {
      defaultValue: "Карта Капитана"
    }),
    og_site_name: _emberData.default.attr('string', {
      defaultValue: "Карта Капитана"
    }),
    og_description: _emberData.default.attr('string', {
      defaultValue: "Карта Капитана - больше чем квесты"
    }),
    og_image: _emberData.default.attr('string', {
      defaultValue: "https://i.imgur.com/uoa3shC.png"
    }),
    og_image_width: _emberData.default.attr('number', {
      defaultValue: 904
    }),
    og_image_height: _emberData.default.attr('number', {
      defaultValue: 481
    }),
    og_locale: _emberData.default.attr('string', {
      defaultValue: "ru_RU"
    }),
    twitter_card: _emberData.default.attr('string', {
      defaultValue: "summary_large_image"
    }),
    twitter_title: _emberData.default.attr('string', {
      defaultValue: "Карта Капитана"
    }),
    twitter_description: _emberData.default.attr('string', {
      defaultValue: "Карта Капитана - больше чем квесты"
    }),
    twitter_image_src: _emberData.default.attr('string', {
      defaultValue: "https://i.imgur.com/uoa3shC.png"
    }),
    twitter_url: _emberData.default.attr('string', {
      defaultValue: "https://capcard.ru"
    }),
    twitter_domain: _emberData.default.attr('string', {
      defaultValue: "capcard.ru"
    }),
    twitter_site: _emberData.default.attr('string', {
      defaultValue: "@"
    }),
    twitter_creator: _emberData.default.attr('string', {
      defaultValue: "@..."
    })
  });

  _exports.default = _default;
});