define("questmap/templates/app/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+XvEuECc",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"content-title\",null,[[\"path\",\"modelName\",\"title\",\"icon\"],[\"modals/useradmin\",\"useradmin\",\"Пользователи\",\"plus\"]]],false],[0,\"\\n\"],[4,\"unless\",[[24,[\"modal\",\"refresh\"]]],null,{\"statements\":[[1,[28,\"tables/table-index\",null,[[\"hideSearch\",\"columns\",\"modalPath\",\"height\",\"modelName\"],[true,[24,[\"columns\"]],\"modals/useradmin\",\"75vh\",\"useradmin\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/app/users.hbs"
    }
  });

  _exports.default = _default;
});