define("questmap/templates/components/tables/table-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hx/iswiA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"loader\"],[8],[0,\"Загрузка...\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/components/tables/table-loader.hbs"
    }
  });

  _exports.default = _default;
});