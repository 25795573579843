define("questmap/templates/components/tables/reviews/questroom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JZtLMcaK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[8],[1,[24,[\"row\",\"delta\"]],false],[0,\" \"],[7,\"img\",true],[10,\"class\",\"emo\"],[11,\"src\",[29,[\"/assets/img/emotions/emotion\",[24,[\"row\",\"emotion\"]],\".png\"]]],[8],[9],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"text-sm\"],[8],[1,[24,[\"row\",\"questroom_title\"]],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/components/tables/reviews/questroom.hbs"
    }
  });

  _exports.default = _default;
});