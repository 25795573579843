define("questmap/validators/cantblank", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Cantblank = _base.default.extend({
    validate(value) {
      return value ? true : "Поле не может быть пустым";
    }

  });

  Cantblank.reopenClass({
    getDependentsFor()
    /* attribute, options */
    {
      return [];
    }

  });
  var _default = Cantblank;
  _exports.default = _default;
});