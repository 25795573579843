define("questmap/templates/app/agents/view/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1xhN2ylS",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"filters/orders\",null,[[\"searchParams\",\"constParams\"],[[24,[\"searchParams\"]],[24,[\"constParams\"]]]]],false],[0,\"\\n\\n\"],[4,\"unless\",[[24,[\"modal\",\"refresh\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"tables/table-index\",null,[[\"columns\",\"searchParams\",\"height\",\"modalPath\",\"constParams\",\"modelName\"],[[24,[\"columns\"]],[24,[\"searchParams\"]],\"70vh\",\"modals/orderdetails\",[24,[\"constParams\"]],\"order\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/app/agents/view/index.hbs"
    }
  });

  _exports.default = _default;
});