define("questmap/templates/app/players/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Sc/a8xvn",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"content-title\",null,[[\"path\",\"model\",\"title\",\"icon\"],[\"modals/player\",[24,[\"model\"]],[24,[\"model\",\"fullname\"]],\"pencil\"]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"viewNavWrap\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[8],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"route\"],[\"app.players.view.index\"]],{\"statements\":[[0,\"Инфо\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"route\"],[\"app.players.view.orders\"]],{\"statements\":[[0,\"Записи (\"],[1,[24,[\"model\",\"orders_count\"]],false],[0,\")\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"route\"],[\"app.players.view.reviews\"]],{\"statements\":[[0,\"Отзывы (\"],[1,[24,[\"model\",\"reviews_count\"]],false],[0,\")\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"route\"],[\"app.players.view.place-reviews\"]],{\"statements\":[[0,\"Отзывы партнеров (\"],[1,[24,[\"model\",\"place_reviews_count\"]],false],[0,\")\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"route\"],[\"app.players.view.balance\"]],{\"statements\":[[0,\"Отчет\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"route\"],[\"app.players.view.adjustments\"]],{\"statements\":[[0,\"Корректировки\"]],\"parameters\":[]},null],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[8],[0,\"\\n\\t\\t\"],[7,\"button\",false],[3,\"action\",[[23,0,[]],[23,0,[\"adjustments\",\"create\"]],\"playeradjustment\",\"player\",[24,[\"model\"]]]],[8],[0,\" \\n\\t\\t\\tБаланс \"],[1,[24,[\"model\",\"balance\"]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/app/players/view.hbs"
    }
  });

  _exports.default = _default;
});