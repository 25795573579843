define("questmap/components/tables/table-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement() {
      let date = moment(this.get('value')).format('DD.MM.YY') + ' ' + moment(this.get('value')).format('HH:mm');
      this.set('date', date);
    }

  });

  _exports.default = _default;
});