define("questmap/components/content-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    //modal: service(),
    ajax: Ember.inject.service(),
    classNames: ["contentTitle"],

    async didInsertElement() {
      const {
        countNotification,
        allPlayers
      } = await this.get('ajax').request(`/useradmins/countNotifications`);
      this.set('countNotification', countNotification);
      this.set('allPlayers', allPlayers);
    },

    actions: {
      new() {
        if (this.model) {
          this.modal.open(this.path, this.model);
        } else {
          this.modal.open(this.path, false, this.modelName);
        }
      }

    }
  });

  _exports.default = _default;
});