define("questmap/routes/app/players/view/payments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return this.modelFor("app.players.view").get('id');
    },

    async setupController(controller, model) {
      controller.set('columns', [{
        label: 'Дата',
        cellComponent: 'tables/table-date',
        valuePath: 'createdAt'
      }, {
        label: 'Сумма',
        valuePath: 'sum'
      }, {
        label: 'Код платежа',
        valuePath: 'paymentId'
      }]);
      controller.set('constParams', {
        player: model
      });
    }

  });

  _exports.default = _default;
});