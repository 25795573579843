define("questmap/components/tables/orders/changed-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    val: Ember.computed('row.old_status', 'row.new_status', function () {
      let {
        old_status,
        new_status
      } = this.get('row').getProperties('old_status', 'new_status');
      return old_status ? statuses[old_status] + ' -> ' + statuses[new_status] : statuses[new_status];
    })
  });

  _exports.default = _default;
  const statuses = {
    "empty": "Не указан",
    "canceled": "Отменен",
    "accepted": "Состоялся"
  };
});