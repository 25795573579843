define("questmap/components/profiles/profile-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),

    didInsertElement() {//$(".phone input").mask("+7(999)999-99-99")
    },

    actions: {
      save(model) {
        this.sendAction('save', model, 'profiles.view.questrooms', true);
      },

      destroyProfile(model) {
        this.sendAction('destroyModel', model, 'profiles.view');
      },

      save(model) {
        this.sendAction('save', model, 'profiles.view.questrooms', true);
      },

      async sendPassword(model) {
        try {
          let res = await this.get('ajax').request('/profiles/sendPassword?id=' + model.get('id'));
          this.get('alerts').showAlert('success', res.message);
        } catch (err) {
          console.log(err);
          this.get('alerts').showAlert('error');
        }
      }

    }
  });

  _exports.default = _default;
});