define("questmap/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    alerts: Ember.inject.service(),
    roles: ["Администратор", "Директор"],
    adminroles: ["Менеджер", "Супер пользователь"],
    numbers10: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    numbers5: ["0", "1", "2", "3", "4"],
    ranks: ["Матрос", "Мичман", "Лейтенант", "Капитан", "Генерал", "Адмирал флота"],
    settingsTypes: ["Клиентский договор", "Агентский договор", "Договор для квестов", "Телефон тех подержки"],
    quilOptions: {
      theme: "snow",
      modules: {
        toolbar: [[{
          header: [2, 3, 4, false]
        }], ["bold", "italic", "underline", "strike"], [{
          color: []
        }], [{
          list: "ordered"
        }, {
          list: "bullet"
        }], [{
          indent: "-1"
        }, {
          indent: "+1"
        }], [{
          align: []
        }], ["link"], ["clean"]]
      }
    },
    actions: {
      async destroy(model, path) {
        if (!confirm("Вы уверены?")) return false;

        try {
          await model.destroyRecord();
          this.alerts.showAlert("success");
          if (path) this.transitionTo(path);
        } catch (err) {
          console.log(err);
          this.alerts.showAlert("error");
        }
      },

      saveText(value) {
        this.set("model.title", value.root.innerHTML);
      }

    }
  });

  _exports.default = _default;
});