define("questmap/models/useradmin", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('cantblank'),
    email: (0, _emberCpValidations.validator)('cantblank')
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    access: (0, _model.attr)('boolean'),
    password: (0, _model.attr)('string'),
    confirm_password: (0, _model.attr)('string'),
    role: (0, _model.attr)('string'),
    //super, manager, director, admin
    last_visit: (0, _model.attr)('number')
  });

  _exports.default = _default;
});