define("questmap/templates/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bgObgSRY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"login_wrap\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"login\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"login_header\"],[8],[7,\"img\",true],[10,\"src\",\"/assets/img/logo.svg\"],[10,\"alt\",\"\"],[8],[9],[0,\"\\n        \"],[7,\"div\",true],[8],[7,\"span\",true],[8],[0,\"Карта\"],[9],[0,\"Капитана\"],[9],[0,\"\\n        \"],[7,\"p\",true],[8],[0,\"Восстановление пароля\"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"entry\"],[8],[0,\"\\n        \"],[1,[22,\"forms/reset-password\"],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"links\"],[8],[0,\"\\n        \"],[4,\"link-to\",null,[[\"route\"],[\"login\"]],{\"statements\":[[0,\"Войти\"]],\"parameters\":[]},null],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/reset-password.hbs"
    }
  });

  _exports.default = _default;
});