define("questmap/templates/app/players/view/place-reviews", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1dsWw6W4",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"tables/table-index\",null,[[\"columns\",\"constParams\",\"modalPath\",\"height\",\"modelName\"],[[24,[\"columns\"]],[24,[\"constParams\"]],\"modals/place-review\",\"60vh\",\"place-review\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/app/players/view/place-reviews.hbs"
    }
  });

  _exports.default = _default;
});