define("questmap/templates/components/filters/reviews", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5umrTTbv",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"bootstrap/form-select\",null,[[\"value\",\"options\"],[[24,[\"accepted\"]],[24,[\"accepteds\"]]]]],false],[0,\"\\n\\n\"],[1,[28,\"bootstrap/form-select\",null,[[\"value\",\"options\"],[[24,[\"hidden\"]],[24,[\"hiddens\"]]]]],false],[0,\"\\n    \\n\"],[4,\"unless\",[[24,[\"hideReal\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"bootstrap/form-select\",null,[[\"value\",\"options\"],[[24,[\"fake\"]],[24,[\"fakes\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"unless\",[[24,[\"withoutStartRange\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"elements/calendar-range\",null,[[\"range\"],[[24,[\"range\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/components/filters/reviews.hbs"
    }
  });

  _exports.default = _default;
});