define("questmap/templates/components/bootstrap/form-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x7ww1C4j",
    "block": "{\"symbols\":[\"i\"],\"statements\":[[0,\"\\n\\t\"],[7,\"label\",true],[10,\"class\",\"control-label\"],[8],[1,[22,\"label\"],false],[9],[0,\"\\n  \\n\"],[4,\"power-select\",null,[[\"options\",\"selected\",\"placeholder\",\"searchEnabled\",\"searchField\",\"allowClear\",\"onChange\"],[[24,[\"options\"]],[24,[\"value\"]],[24,[\"placeholder\"]],[24,[\"searchEnabled\"]],[24,[\"searchField\"]],[24,[\"allowClear\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"value\"]]],null]],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,1,[\"title\"]]],null,{\"statements\":[[0,\"    \\t\"],[1,[23,1,[\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \\t\"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/components/bootstrap/form-select.hbs"
    }
  });

  _exports.default = _default;
});