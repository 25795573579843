define("questmap/models/order", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    sid: _emberData.default.attr('string'),
    questroom: _emberData.default.belongsTo('questroom', {
      async: true
    }),
    questroom_title: _emberData.default.attr('string'),
    player: _emberData.default.belongsTo('player', {
      async: true
    }),
    agent: _emberData.default.belongsTo('agent', {
      async: true
    }),
    profile: _emberData.default.belongsTo('profile', {
      async: true
    }),
    fullname: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    review: _emberData.default.belongsTo('review', {
      async: true
    }),
    review_delta: _emberData.default.attr('string'),
    date: _emberData.default.attr('string'),
    players: _emberData.default.attr('number'),
    discount: _emberData.default.attr('number'),
    search: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    comment: _emberData.default.attr('string'),
    online_payment: _emberData.default.attr('number'),
    playerBalance: _emberData.default.hasMany('playerbalance', {
      async: true
    }),
    profileBalance: _emberData.default.hasMany('profilebalance', {
      async: true
    }),
    agentBalance: _emberData.default.hasMany('agentbalance', {
      async: true
    }),
    sum: _emberData.default.attr('string'),
    sum_to_pay: _emberData.default.attr('string'),
    player_fee: _emberData.default.attr('string'),
    profile_fee: _emberData.default.attr('string'),
    agent_fee: _emberData.default.attr('string'),
    log_status: _emberData.default.attr('string'),
    source: _emberData.default.attr('string'),
    not_save: _emberData.default.attr("boolean"),
    profile_name: _emberData.default.attr("string"),
    human_time: Ember.computed('date', function () {
      return moment.utc(this.date).format('HH:mm');
    }),
    toPay: Ember.computed('sum', function () {
      return Math.floor(this.sum - this.sum / 100 * this.discount);
    }),
    human_status: Ember.computed('status', function () {
      let statuses = {
        accepted: "Cостоялся",
        canceled: "Отменен",
        empty: "Не установлен"
      };
      return statuses[this.status];
    }),
    human_date: Ember.computed('date', function () {
      return moment.utc(this.date).format('DD.MM.YY');
    })
  });

  _exports.default = _default;
});