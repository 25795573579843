define("questmap/routes/app/orders/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    infinity: Ember.inject.service(),

    model() {
      return [{
        label: '',
        valuePath: 'log_status',
        cellComponent: 'tables/orders/status',
        width: '10px'
      }, {
        label: 'Квест',
        valuePath: 'questroom_title',
        cellComponent: 'tables/orders/title'
      }, {
        label: 'Дата',
        valuePath: 'date',
        cellComponent: 'tables/orders/date'
      }, {
        label: 'Клиент',
        valuePath: 'phone',
        cellComponent: 'tables/orders/player',
        width: '200px'
      }, {
        label: 'Сумма игры',
        valuePath: 'sum',
        cellComponent: 'tables/orders/cost'
      }, {
        label: 'Посетил',
        valuePath: 'status',
        cellComponent: 'tables/table-accept',
        width: '80px'
      }, {
        label: 'Оплата',
        valuePath: 'online_payment',
        width: '30px'
      }];
    } // async setupController(controller, model) {
    // }


  });

  _exports.default = _default;
});