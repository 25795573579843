define("questmap/validators/phone", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Phone = _base.default.extend({
    validate(value) {
      let phone = '';
      let f = '';

      if (value) {
        phone = '' + value.replace(/[^0-9]/gim, '');
        f = phone.slice(0, 2);
      }

      return phone.length === 11 && (f === '89' || f === "79") ? true : "Телефон имеет не допустимый формат!";
    }

  });

  Phone.reopenClass({
    getDependentsFor()
    /* attribute, options */
    {
      return [];
    }

  });
  var _default = Phone;
  _exports.default = _default;
});