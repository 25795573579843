define("questmap/routes/app/profiles/view/questrooms/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      // let xxx = {}
      // this.store.findRecord("questroom", params.questroom_id).then( (questroom)=>{
      // 	xxx.questroom = questroom;
      //  this.store.query('review', { questroom_title: questroom.title }).then((review)=>{
      // 		xxx.review = review;
      // 		console.log(xxx)
      // 		return questroom;
      // });
      // })
      // console.log(this.store.query('review', { questroom_title: this.store.findRecord("questroom", params.questroom_id).title }))
      // this.store.findAll('review').then(review => {})
      return this.store.findRecord("questroom", params.questroom_id).then(questroom => {
        console.log(questroom.title);
        return this.store.findAll('review').then(review => {
          // console.log(review)
          review.forEach(element => {
            console.log(element.questroom_title);
          });
          let reviews = review.filter(review => review.questroom_title === questroom.title); // review = review.filter(review => review.questroom_title === questroom.title);

          console.log(review);
          review.forEach(el => {
            el.createdDate = moment(el.createdDate).format('DD.MM.YY');
          });
          return {
            questroom: questroom,
            review: reviews
          };
        });
      }); // ///////////////////////////////////////////////////////
      // return {
      // 	questroom: this.store.findRecord("questroom", params.questroom_id),
      // 	review: this.store.findAll('review')
      // }
      /////////////////////////////////////////////////////////////
    },

    actions: {
      willTransition(transition) {
        this.controllerFor(transition.from.name).get('model').rollbackAttributes();
      }

    }
  });

  _exports.default = _default;
});