define("questmap/models/invite", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    createdAt: _emberData.default.attr('number'),
    questroom_title: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    order: _emberData.default.attr('string'),
    profile: _emberData.default.attr('string'),
    profile_title: _emberData.default.attr('string'),
    player_card: _emberData.default.attr('string'),
    sum: _emberData.default.attr('number'),
    formatDate: function () {
      return moment.utc(this.get('createdAt')).format('DD.MM.YYYY');
    }.property('createdAt'),
    formatActual: function () {
      let dif = Math.floor(30 - (Date.now() - this.get('createdAt')) / 86400000);
      console.log(dif);
      return dif + ' дн.';
    }.property('createdAt')
  });

  _exports.default = _default;
});