define("questmap/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    alerts: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    beforeModel() {
      moment.locale('ru');
      return this._loadCurrentUser();
    },

    async sessionAuthenticated() {
      let _super = this._super;
      await this._loadCurrentUser();

      _super.call(this, ...arguments);
    },

    _loadCurrentUser() {
      return this.get('currentUser').load().catch(() => this.get('session').invalidate());
    },

    actions: {
      redirect(path, model) {
        model ? this.transitionTo(path, model) : this.transitionTo(path);
      }

    }
  });

  _exports.default = _default;
});