define("questmap/templates/app/partners/view/places/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tPAYeIg5",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"place-form\",null,[[\"categories\",\"model\",\"save\"],[[24,[\"categories\"]],[24,[\"model\"]],\"save\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/app/partners/view/places/create.hbs"
    }
  });

  _exports.default = _default;
});