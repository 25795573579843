define("questmap/components/tables/reviews/review", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    review: Ember.computed('value', function () {
      if (!this.value) return 'Аудио';
      if (this.value.length <= 70) return this.value;
      return this.value.slice(0, 70) + '...';
    })
  });

  _exports.default = _default;
});