define("questmap/models/profilemonthstat", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    sid: (0, _model.attr)('string'),
    profile: (0, _model.attr)('string'),
    orders: (0, _model.attr)('number'),
    accepted_orders: (0, _model.attr)('number'),
    empty_orders: (0, _model.attr)('number'),
    canceled_orders: (0, _model.attr)('number'),
    sum_to_pay: (0, _model.attr)('number'),
    fee: (0, _model.attr)('number')
  });

  _exports.default = _default;
});