define("questmap/models/partner", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // form
    title: (0, _model.attr)('string', {
      required: true
    }),
    franchiseTitle: (0, _model.attr)('string'),
    uid: (0, _model.attr)('string'),
    address: (0, _model.attr)('string', {
      required: true
    }),
    email: (0, _model.attr)('string', {
      required: true
    }),
    site: (0, _model.attr)('string'),
    comment: (0, _model.attr)('string'),
    phone: (0, _model.attr)('string', {
      required: true
    }),
    managerContacts: (0, _model.attr)('string', {
      required: true
    }),
    allowEdit: (0, _model.attr)('boolean'),
    //cache
    maxDiscount: (0, _model.attr)('string'),
    reviewsCount: (0, _model.attr)('number'),
    blackPearlsCount: (0, _model.attr)('number'),
    whitePearlsCount: (0, _model.attr)('number'),
    orders: (0, _model.attr)('number'),
    uniqOrders: (0, _model.attr)('number'),
    deactivated: (0, _model.attr)('boolean'),
    setupWidget: (0, _model.attr)('boolean'),
    //relations
    places: (0, _model.hasMany)('place', {
      async: true
    }),
    categories: (0, _model.hasMany)('place-category', {
      async: true
    }),
    subcategories: (0, _model.hasMany)('place-type', {
      async: true
    }),
    city: (0, _model.attr)('string'),
    cityFront: Ember.computed('city', function () {
      if (this.city === undefined) {
        return 'Москва';
      }

      return `${this.city}`;
    }),
    citySelect: Ember.A(['Москва', 'Санкт-Петербург'])
  });

  _exports.default = _default;
});