define("questmap/templates/app/profiles/view/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m1OQCzsM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\",false],[12,\"class\",\"button\"],[3,\"action\",[[23,0,[]],\"createUser\",[24,[\"constParams\"]]]],[8],[0,\"Новый сотрудник\"],[9],[0,\"\\n\\n\"],[4,\"unless\",[[24,[\"modal\",\"refresh\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"tables/table-index\",null,[[\"hideSearch\",\"columns\",\"modalPath\",\"height\",\"constParams\",\"modelName\"],[true,[24,[\"columns\"]],\"modals/user\",\"65vh\",[24,[\"constParams\"]],\"user\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/app/profiles/view/users.hbs"
    }
  });

  _exports.default = _default;
});