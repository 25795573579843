define("questmap/models/place-order", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    createdAt: (0, _model.attr)('number'),
    place_title: (0, _model.attr)('string'),
    player_card: (0, _model.attr)('string'),
    player_discount: (0, _model.attr)('string'),
    hasReview: (0, _model.attr)('boolean'),
    hasPerl: (0, _model.attr)('boolean'),
    uniq: (0, _model.attr)('boolean'),
    player: (0, _model.belongsTo)('player', {
      async: true
    }),
    place: (0, _model.belongsTo)('place', {
      async: true
    }),
    review: (0, _model.belongsTo)('place-review', {
      async: true
    }),
    review_delta: (0, _model.attr)('number'),
    human_time: Ember.computed('createdAt', function () {
      return moment.utc(this.date).format('HH:mm');
    }),
    human_date: Ember.computed('createdAt', function () {
      return moment.utc(this.date).format('DD.MM.YY');
    })
  });

  _exports.default = _default;
});