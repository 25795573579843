define("questmap/templates/app/players/view/orders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0ELxMlVT",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"filters/orders\",null,[[\"searchParams\",\"withoutStartRange\",\"constParams\"],[[24,[\"searchParams\"]],true,[24,[\"constParams\"]]]]],false],[0,\"\\n\\n\"],[1,[28,\"tables/table-index\",null,[[\"columns\",\"height\",\"modalPath\",\"constParams\",\"searchParams\",\"modelName\"],[[24,[\"columns\"]],\"45vh\",\"modals/orderdetails\",[24,[\"constParams\"]],[24,[\"searchParams\"]],\"order\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/app/players/view/orders.hbs"
    }
  });

  _exports.default = _default;
});