define("questmap/templates/app/categories/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j2+5Z8DN",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"content-title\",null,[[\"path\",\"model\",\"title\",\"icon\"],[\"modals/place-category\",[24,[\"model\"]],[24,[\"model\",\"title\"]],\"edit\"]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"viewNavWrap\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[8],[0,\"\\n\\t\\t\"],[7,\"button\",false],[3,\"action\",[[23,0,[]],\"create\",[24,[\"model\"]]]],[8],[0,\" \\n\\t\\t\\tДобавить подкатегорию\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"],[4,\"unless\",[[24,[\"modal\",\"refresh\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"tables/table-index\",null,[[\"columns\",\"hideSearch\",\"constParams\",\"modalPath\",\"height\",\"modelName\"],[[24,[\"columns\"]],true,[24,[\"constParams\"]],\"modals/place-type\",\"60vh\",\"place-type\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/app/categories/view.hbs"
    }
  });

  _exports.default = _default;
});