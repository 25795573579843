define("questmap/routes/app/categories/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    crud: Ember.inject.service(),

    async model(params) {
      return await this.store.findRecord("place-category", params.category_id);
    },

    setupController(controller, model) {
      controller.set("model", model);
      controller.set("constParams", {
        category: model.get("id")
      });
      controller.set("columns", [{
        label: "Название",
        valuePath: "title"
      }, {
        label: "Кол-во заведений",
        valuePath: "placesCount"
      }]);
    },

    actions: {
      create(model) {
        const type = this.store.createRecord("place-type", {
          category: model
        });
        this.modal.open("modals/place-type", type);
      }

    }
  });

  _exports.default = _default;
});