define("questmap/models/place", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    title: (0, _model.attr)('string', {
      required: true
    }),
    howToFind: (0, _model.attr)('string', {
      required: true
    }),
    maxDiscount: (0, _model.attr)('string', {
      required: true
    }),
    description: (0, _model.attr)('string', {
      required: true
    }),
    discountRule: (0, _model.attr)('string', {
      required: true
    }),
    discountOption: (0, _model.attr)('string', {
      required: true
    }),
    video: (0, _model.attr)('string'),
    image: (0, _model.attr)('string'),
    images: (0, _model.attr)('array', {
      defaultValue: () => []
    }),
    phone1: (0, _model.attr)('string'),
    phone2: (0, _model.attr)('string'),
    phone3: (0, _model.attr)('string'),
    subPhone1: (0, _model.attr)('string'),
    subPhone2: (0, _model.attr)('string'),
    subPhone3: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    priceFrom: (0, _model.attr)('string'),
    service: (0, _model.attr)('number'),
    clean: (0, _model.attr)('number'),
    price: (0, _model.attr)('number'),
    emotion: (0, _model.attr)('number'),
    atmosphere: (0, _model.attr)('number'),
    delta: (0, _model.attr)('number'),
    reviewsCount: (0, _model.attr)('number'),
    blackPearlsCount: (0, _model.attr)('number'),
    whitePearlsCount: (0, _model.attr)('number'),
    orders: (0, _model.attr)('number'),
    uniqOrders: (0, _model.attr)('number'),
    address: (0, _model.attr)('string', {
      required: true
    }),
    parking: (0, _model.attr)('string'),
    disctrict: (0, _model.attr)('string'),
    mainMetro: (0, _model.attr)(),
    metro: (0, _model.attr)(),
    latitude: (0, _model.attr)('string'),
    longitude: (0, _model.attr)('string'),
    partner: (0, _model.belongsTo)('partner', {
      async: true
    }),
    category: (0, _model.belongsTo)('place-category', {
      async: true
    }),
    subcategory: (0, _model.belongsTo)('place-type', {
      async: true
    }),
    categories: (0, _model.hasMany)('place-category', {
      async: true,
      defaultValue: () => []
    }),
    subcategories: (0, _model.hasMany)('place-type', {
      async: true,
      defaultValue: () => []
    }),
    access: (0, _model.attr)('boolean'),
    city: (0, _model.attr)('string'),
    cityFront: Ember.computed('city', function () {
      if (this.city === undefined) {
        return 'Москва';
      }

      return `${this.city}`;
    })
  });

  _exports.default = _default;
});