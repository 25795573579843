define("questmap/templates/components/modal-window", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zswfKUQb",
    "block": "{\"symbols\":[\"modal\",\"&default\"],\"statements\":[[5,\"bs-modal\",[],[[\"@open\",\"@size\",\"@onHidden\",\"@onHide\"],[true,[22,\"size\"],[28,\"action\",[[23,0,[]],[23,0,[\"modal\",\"close\"]]],null],[28,\"action\",[[23,0,[]],\"close\"],null]]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\n    \"],[7,\"div\",false],[12,\"class\",\"closeButton\"],[3,\"action\",[[23,0,[]],[23,1,[\"close\"]]]],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"fa fa-window-close\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[14,2],[0,\"\\n    \\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/components/modal-window.hbs"
    }
  });

  _exports.default = _default;
});