define("questmap/templates/app/agents/payments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cg1aune9",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"content-title\",null,[[\"title\"],[\"Выплаты\"]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"viewNavWrap\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[8],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"route\"],[\"app.agents.index\"]],{\"statements\":[[0,\"Список\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"route\"],[\"app.agents.payments\"]],{\"statements\":[[0,\"Выплаты\"]],\"parameters\":[]},null],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\"],[1,[28,\"tables/table-index\",null,[[\"columns\",\"height\",\"modelName\"],[[24,[\"columns\"]],\"70vh\",\"agentadjustment\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/app/agents/payments.hbs"
    }
  });

  _exports.default = _default;
});