define("questmap/templates/app/profiles/view/payments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gYPnjC4I",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"tables/table-index\",null,[[\"columns\",\"height\",\"constParams\",\"modelName\"],[[24,[\"columns\"]],\"75vh\",[24,[\"constParams\"]],\"profilepay\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/app/profiles/view/payments.hbs"
    }
  });

  _exports.default = _default;
});