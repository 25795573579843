define("questmap/services/current-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),

    load() {
      if (this.get('session.isAuthenticated')) {
        return this.get('store').queryRecord('useradmin', {
          me: true
        }).then(user => {
          this.set('user', user);
        });
      } else {
        return Ember.RSVP.resolve();
      }
    }

  });

  _exports.default = _default;
});