define("questmap/routes/app/agents/view/clients", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return this.modelFor("app.agents.view").get('id');
    },

    async setupController(controller, model) {
      controller.set('columns', [{
        label: 'Клиент',
        valuePath: 'fullname'
      }, {
        label: 'Кол-во игр',
        valuePath: 'orders_count'
      }]);
      controller.set('constParams', {
        agent: model
      });
    }

  });

  _exports.default = _default;
});