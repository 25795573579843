define("questmap/router", ["exports", "@babel/runtime/helpers/esm/defineProperty", "questmap/config/environment"], function (_exports, _defineProperty2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "location", _environment.default.locationType);
      (0, _defineProperty2.default)(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route('login');
    this.route('app', function () {
      this.route('orders', function () {});
      this.route('profiles', function () {
        this.route('view', {
          path: ":profile_id"
        }, function () {
          this.route('questrooms', function () {
            this.route('edit', {
              path: ":questroom_id"
            });
            this.route('new');
          });
          this.route('balance');
          this.route('adjustments');
          this.route('payments');
          this.route('statistic');
          this.route('index');
          this.route('orders');
          this.route('users');
          this.route('month');
        });
      });
      this.route('players', function () {
        this.route('view', {
          path: ":player_id"
        }, function () {
          this.route('balance');
          this.route('reviews');
          this.route('orders');
          this.route('adjustments');
          this.route('payments');
          this.route('place-reviews');
        });
      });
      this.route('agents', function () {
        this.route('view', {
          path: ":agent_id"
        }, function () {
          this.route('payments');
          this.route('clients');
          this.route('balance');
        });
        this.route('payments');
      });
      this.route('users');
      this.route('settings', function () {});
      this.route('mobile');
      this.route('reviews');
      this.route('questrooms');
      this.route('meta', function () {});
      this.route('magic', function () {
        this.route('edit', {
          path: ":magician_id"
        });
      });
      this.route('payments', function () {
        this.route('players');
      });
      this.route('partners', function () {
        this.route('view', {
          path: ':partner_id'
        }, function () {
          this.route('places', function () {
            this.route('view', {
              path: ':place_id'
            });
            this.route('create');
            this.route('users');
          });
          this.route('orders');
          this.route('users');
          this.route('reviews');
        });
      });
      this.route('categories', function () {
        this.route('view', {
          path: ':category_id'
        });
      });
      this.route('partners-reviews');
    });
    this.route('reset-password');
    this.route('meta', function () {});
  });
});