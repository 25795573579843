define("questmap/routes/app/meta/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    infinity: Ember.inject.service(),

    model() {
      return {
        columns: [{
          label: 'url',
          valuePath: 'url',
          cellComponent: 'tables/meta/url'
        }]
      };
    }

  });

  _exports.default = _default;
});