define("questmap/templates/app/magic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7KyYvVLX",
    "block": "{\"symbols\":[\"m\"],\"statements\":[[1,[28,\"input\",null,[[\"value\"],[[24,[\"title\"]]]]],false],[0,\"\\n\"],[7,\"button\",false],[12,\"class\",\"button\"],[3,\"action\",[[23,0,[]],\"create\",[24,[\"title\"]]]],[8],[0,\"Создать\"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[8],[4,\"link-to\",null,[[\"route\",\"model\"],[\"app.magic.edit\",[23,1,[]]]],{\"statements\":[[1,[23,1,[\"title\"]],false]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[7,\"p\",true],[8],[0,\"Выбранный волшебник:\"],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/app/magic.hbs"
    }
  });

  _exports.default = _default;
});