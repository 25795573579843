define("questmap/models/agent", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('cantblank'),
    email: (0, _emberCpValidations.validator)('cantblank'),
    phone: (0, _emberCpValidations.validator)('phone'),
    surname: (0, _emberCpValidations.validator)('cantblank')
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _model.attr)('string'),
    surname: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    code: (0, _model.attr)('string'),
    contract: (0, _model.attr)('string'),
    phone: (0, _model.attr)('phone'),
    photo: (0, _model.attr)('string'),
    work: (0, _model.attr)('string'),
    orders: (0, _model.attr)('number'),
    balance: (0, _model.attr)('number'),
    access: (0, _model.attr)('boolean'),
    clients: (0, _model.attr)('number'),
    orders_count: (0, _model.attr)('number'),
    last_visit: (0, _model.attr)('number'),
    last_device: (0, _model.attr)('string'),
    fullname: Ember.computed('name', 'surname', function () {
      return `${this.name} ${this.surname}`;
    })
  });

  _exports.default = _default;
});