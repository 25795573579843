define("questmap/templates/components/elements/model-select-multiple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/7QulOkU",
    "block": "{\"symbols\":[\"i\"],\"statements\":[[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"control-label\"],[8],[1,[22,\"label\"],false],[0,\" \"],[9],[0,\"\\n\"],[4,\"power-select-multiple\",null,[[\"options\",\"searchEnabled\",\"selected\",\"searchField\",\"onChange\"],[[24,[\"options\"]],true,[24,[\"value\"]],\"title\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"value\"]]],null]],null]]],{\"statements\":[[0,\"\\n        \"],[1,[23,1,[\"title\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/components/elements/model-select-multiple.hbs"
    }
  });

  _exports.default = _default;
});