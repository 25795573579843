define("questmap/templates/app/profiles/view/orders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s0FJf2S3",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"filters/orders\",null,[[\"searchParams\",\"constParams\"],[[24,[\"searchParams\"]],[24,[\"constParams\"]]]]],false],[0,\"\\n\\n\"],[1,[28,\"tables/table-index\",null,[[\"columns\",\"searchParams\",\"height\",\"modalPath\",\"constParams\",\"modelName\"],[[24,[\"columns\"]],[24,[\"searchParams\"]],\"50vh\",\"modals/orderdetails\",[24,[\"constParams\"]],\"order\"]]],false],[0,\"\\n\\n\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/app/profiles/view/orders.hbs"
    }
  });

  _exports.default = _default;
});