define("questmap/models/expense", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    createdAt: _emberData.default.attr('number'),
    month: _emberData.default.attr('string'),
    orders: _emberData.default.attr('number'),
    accepted: _emberData.default.attr('number'),
    canceled: _emberData.default.attr('number'),
    balance: _emberData.default.attr('number')
  });

  _exports.default = _default;
});