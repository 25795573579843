define("questmap/templates/modals/partner-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/w5Qkedj",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[4,\"modal-window\",null,[[\"model\"],[[24,[\"model\"]]]],{\"statements\":[[0,\"\\t\"],[5,\"bs-form\",[],[[\"@model\",\"@onSubmit\"],[[22,\"model\"],[28,\"action\",[[23,0,[]],[23,0,[\"modal\",\"saveModal\"]]],null]]],{\"statements\":[[0,\"\\n\\t\\t\"],[6,[23,1,[\"element\"]],[],[[\"@controlType\",\"@label\",\"@property\"],[\"text\",\"Имя\",\"name\"]]],[0,\"\\n\\t\\t\"],[6,[23,1,[\"element\"]],[],[[\"@controlType\",\"@label\",\"@property\"],[\"email\",\"Email\",\"email\"]]],[0,\" \\n\\t    \"],[6,[23,1,[\"element\"]],[],[[\"@controlType\",\"@label\",\"@property\"],[\"checkbox\",\"Доступ к системе\",\"access\"]]],[0,\"\\n\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"flexRow\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"isNew\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[5,\"bs-button\",[],[[\"@defaultText\",\"@buttonType\",\"@type\",\"@disabled\"],[\"Создать\",\"submit\",\"primary\",[23,1,[\"isSubmitting\"]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\\t\"],[5,\"bs-button\",[],[[\"@defaultText\",\"@buttonType\",\"@type\",\"@disabled\"],[\"Сохранить\",\"submit\",\"primary\",[23,1,[\"isSubmitting\"]]]]],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"currentUser\",\"user\",\"role\"]],\"Супер пользователь\"],null]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[7,\"button\",false],[12,\"class\",\"btn btn-warning\"],[3,\"action\",[[23,0,[]],[23,0,[\"modal\",\"destroy\"]],\"app.profiles\"]],[8],[0,\"Удалить\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\t\\t\"],[9],[0,\"\\n\\t\\t\\n\\t\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/modals/partner-user.hbs"
    }
  });

  _exports.default = _default;
});