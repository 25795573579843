define("questmap/components/elements/calendar-range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-group'],
    // range: {
    //     start: moment().startOf("month").toDate(),
    //     end: moment().endOf("month").toDate()
    // },
    formatDate: Ember.computed("range.start", "range.end", function () {
      if (!this.range) return "-";
      let s = this.range.start ? moment(this.range.start).format('DD.MM.YY') : '.';
      let e = this.range.end ? moment(this.range.end).format('DD.MM.YY') : '.';
      return `${s} - ${e}`;
    })
  });

  _exports.default = _default;
});