define("questmap/models/agentadjustment", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    sum: (0, _emberCpValidations.validator)('cantblank'),
    agent: (0, _emberCpValidations.validator)('cantblank')
  });

  var _default = _model.default.extend(Validations, {
    createdAt: (0, _model.attr)('number'),
    agent: (0, _model.attr)('string'),
    comment: (0, _model.attr)('string'),
    sum: (0, _model.attr)('number')
  });

  _exports.default = _default;
});