define("questmap/components/elements/price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    formated: Ember.computed("value", "currency", function () {
      return this.value ? this.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0;
    })
  });

  _exports.default = _default;
});