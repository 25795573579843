define("questmap/services/crud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    alerts: Ember.inject.service(),
    router: Ember.inject.service(),
    modelForUpdate: null,

    updateParentModel() {
      if (this.modelForUpdate) modelForUpdate.reload();
    },

    async save(model, path, modelInRedirect) {
      try {
        await model.save();
        this.alerts.showAlert('success');
        if (path) modelInRedirect ? this.router.transitionTo(path, modelInRedirect) : this.router.transitionTo(path);
      } catch (err) {
        console.log(err);
        this.alerts.showAlert('error');
      }
    },

    async destroy(model, path) {
      if (!confirm("Вы уверены?")) return false;

      try {
        await model.destroyRecord();
        this.alerts.showAlert('success');
        if (path) this.router.transitionTo(path);
      } catch (err) {
        this.alerts.showAlert('error');
      }
    }

  });

  _exports.default = _default;
});