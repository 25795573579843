define("questmap/templates/components/filters/place-orders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vbdTMt1B",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"visiting-nav\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"places\",\"length\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"bootstrap/form-select\",null,[[\"value\",\"placeholder\",\"allowClear\",\"options\"],[[24,[\"place\"]],\"Все заведения\",true,[24,[\"places\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"unless\",[[24,[\"withoutStartRange\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"elements/calendar-range\",null,[[\"range\"],[[24,[\"range\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\\n  \\n\"],[4,\"if\",[[24,[\"statistic\"]]],null,{\"statements\":[[0,\"  \"],[7,\"table\",true],[10,\"class\",\"statistic\"],[8],[0,\"\\n    \"],[7,\"thead\",true],[8],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Всего заявок\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Уникальных\"],[9],[0,\" \\n    \"],[9],[0,\"\\n    \"],[7,\"tbody\",true],[8],[0,\"\\n      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[24,[\"statistic\",\"all\"]],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[24,[\"statistic\",\"uniq\",\"length\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/components/filters/place-orders.hbs"
    }
  });

  _exports.default = _default;
});