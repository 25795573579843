define("questmap/routes/app/partners/view/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel() {
      this.modal.close();
    },

    async setupController(controller, model) {
      controller.set('model', model);
      let profiledetail = await this.store.query("profiledetail", {
        profile: model.id
      });
      profiledetail = profiledetail.get("firstObject");
      if (!profiledetail) profiledetail = this.store.createRecord("profiledetail", {
        profile: model.id
      });
      controller.set('profiledetail', profiledetail);
    }

  });

  _exports.default = _default;
});