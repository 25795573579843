define("questmap/components/tables/table-balance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement() {
      this.set('state', this.get('value') < 0 ? 'color-red' : 'color-green');
    }

  });

  _exports.default = _default;
});