define("questmap/templates/components/visual-redactor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GxvKkpAQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"head\",true],[8],[0,\"\\n  \"],[7,\"style\",true],[8],[0,\"\\n    .content { display: none; }\\n    .content::after {\\n      display: block; content: ' ( 'url(href=\\\"sample.php\\\")' ) ';\\n    }\\n    .intLink { cursor: pointer; }\\n    img.intLink { border: 0; width: 30px; }\\n    #toolBar1 select { font-size:10px; background-color: #eff;}\\n    #textBox {\\n      max-width: 500px;\\n      min-height: 200px;\\n      border: 1px solid rgba(155,155,155,.6);\\n      padding: 12px;\\n      overflow: auto;\\n      overflow-x: hidden;\\n    }\\n\\n    #switchBox, label, #btn { cursor: pointer; }\\n\\n    ul {\\n      padding-left: 20px;\\n    }\\n\\n\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n  \"],[7,\"label\",true],[10,\"for\",\"v_editor\"],[10,\"class\",\"control-label\"],[8],[1,[22,\"label\"],false],[0,\"\\n\"],[4,\"if\",[[24,[\"maxWidth\"]]],null,{\"statements\":[[0,\"      \"],[1,[24,[\"value\",\"length\"]],false],[0,\"/\"],[1,[22,\"maxWidth\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"id\",\"v_editor\"],[10,\"style\",\"height: 500px; width: 100%\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/components/visual-redactor.hbs"
    }
  });

  _exports.default = _default;
});