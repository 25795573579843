define("questmap/components/questrooms-form", ["exports", "bootstrap", "questmap/config/environment"], function (_exports, _bootstrap, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    categories: Ember.A(['Топ детских квестов', 'Топ квестов для подростков', 'Топ страшных квестов', 'Топ сложных квестов']),
    questroomTypes: Ember.A(['Эскейп квесты', 'Живые квесты', 'Перформансы', 'Экшен квесты', 'VR квесты', 'Морфеус', 'Квизы', 'Городские квесты', 'Саспенс квесты']),
    questroomApis: Ember.A(['mk', 'questreet', 'endorfinquest', 'questadmin', 'bookform', 'bronibiz', 'mirquest', 'gamebrains', 'questcompas', 'questroom', 'questquest', 'qwestern', 'indiequest', 'parallelquest', 'kinoquest', 'questreet', 'podzemka', 'claustrophobia', 'galaquest', 'questpoliya', 'joyment', 'vrescape', 'kvestliga', 'kvestinfo', 'questreality', 'booquest', 'portkey', 'questout', 'kvestbook', 'pogruzhenye', 'vzaperti', 'razoomgames', 'za-sov', 'ILocked', '4mozga', 'chezakvest', 'nelogika', 'quest-art']),
    questroomLevel: Ember.A([1, 2, 3, 4]),
    questroomFear: Ember.A(['0', '1', '2', '3', '4']),
    //questroomTags: A(["Ограбление", "Логические", "Фантастические", "In English", "Исторические", "По книгам", "По игра", "Побег из тюрьмы", "Детективные", "Расследование", "Квест ужасы", "Антуражные", "Популярные", "Сложные", "Для взрослых", "На Дни рождения", "8 марта", "Романтические", "23 февраля", "Топ квестов", "Круглосуточные", "Квесты", "Эскейпы", "Перформансы", "По фильмам", "Для новичков", "С актерами", "Экшн-квесты", "Хоррор", "Для корпоратива", "Для женщин", "Для мужчин", "Мистические", "С юмором", "Философские", "Для опытных игроков", "Легкие", "Несложные", "Сложные", "Практически непроходимые", "Квест ужасы", "Семейные", "По мультфильмам", "Детские квесты", "По сказкам", "Вселенная Marvel", "Для школьников", "Для детского сада", "Для студентов", "Недорогие", "100% Цена-качеств", "Апокалипсис", "С болью", "С контактом", "Проверка силы духа"]),
    questroomTags: Ember.A(["Для детей", "Для подростков", "Для большой компании", "Страшные", "Нестандартный формат", "На День рождения", "С актерами", "In English", "Апокалипсис", "Виртуальная реальность", "Детективные", "Детские квесты", "Для большой компании", "Для взрослых", "Для корпоратива", "Для новичков", "Для подростков", "Для школьников", "Исторические", "Круглосуточные", "На День рождения", "Недорогие", "О Гарри Поттере", "Ограбление", "По фильмам", "Побег из тюрьмы", "Ролевые квесты", "С актерами", "Семейные", "Сложные", "Спортивные", "Страшные", "Фантастические", "Фэнтези", "Экшн-квесты", "Эротические", "Мистические"]),
    city: Ember.A(['Москва', 'Санкт-Петербург']),
    disctricts: Ember.A(["ЦАО", "САО", "СВАО", "ВАО", "ЮВАО", "ЮАО", "ЮЗАО", "ЗАО", "СЗАО", "Зеленоградский АО", "Новомосковский АО", "Троицкий АО", 'Балашиха', 'Бронницы', 'Дзержинский', 'Долгопрудный', 'Железнодорожный', 'Жуковский', 'Ивантеевка', 'Королев и Юбилейный', 'Котельники', 'Красногвардейский район', 'Ленинский район', 'Лобня', 'Лыткарино', 'Люберцы', 'Пушкино', 'Реутов', 'Фрязино', 'Химки', 'Электросталь']),
    disctrictsSPB: Ember.A(["Адмиралтейский", "Василеостровский", "Выборгский", "Калининский", "Кировский", "Колпинский", "Красногвардейский", "Красносельский", "Кронштадский", "Курортный", "Московский", "Невский", "Петроградский", "Петродворцовый", "Приморский", "Пушкинский", "Фрунзенский", "Центральный", 'Гатчина', 'Зеленогорск', 'Колпино', 'Кронштадт', 'Кудрово', 'Мурино', 'Петергоф', 'Пушкин']),
    stations: Ember.computed(function () {
      const subways = getSubway(this);
      return Ember.A(subways);
    }),
    actions: {
      async generateTitleLatine(model) {
        const {
          title_latine
        } = await fetch(`${_environment.default.host}/questrooms/gen_uniq`, {
          method: 'POST',
          body: JSON.stringify({
            id: model.id,
            title: model.title
          })
        }).then(response => response.json());
        model.set('title_latine', title_latine);
      },

      async save(model) {
        if (model.is_addMetroManually) {
          let data = [];

          if (this.model.city === 'Санкт-Петербург') {
            data = await this.get('ajax').request(`/questrooms/subwaySPB`);
          } else {
            data = await this.get('ajax').request(`/questrooms/subway`);
          }

          const ss = Array.from(new Set(model.metroName));
          const z = ss.map(item => data.filter(d => d.name === item)).flat();
          model.metro = z;
        }

        if (!model.company) {
          alert('Введите название франшизы!');
          return;
        }

        this.crud.save(model, 'app.profiles.view.questrooms.index');
      },

      destroy(model) {
        this.crud.destroy(model, 'app.profiles.view.questrooms.index');
      },

      addMain(img) {
        this.set('model.image', img);
      },

      destroyImage(i) {
        this.get('model.images').removeObject(i);
      },

      newReview(row) {
        // alert()
        let review = this.get("store").createRecord('review', {
          fake: true,
          accepted: true,
          questroom: row
        });
        this.modal.open('modals.review', review);
      },

      async all_reviews(model) {
        let reviews = this.get('store').findAll('review');
        reviews = await this.get('ajax').request(`/reviews?questroom=${model.id}`).then(r => {
          console.log(r);
          return r;
        });
        reviews.filter(r => {
          r.questroom = model.id;
        });
        console.log(this.modal, this.model, reviews);
        this.modal.open('modals/all_reviews', reviews);
      }

    }
  });

  _exports.default = _default;

  async function getSubway(that) {
    if (that.model.city === 'Санкт-Петербург') {
      return await that.get('ajax').request(`/questrooms/subwaySPB`).then(data => {
        const metro = data.reduce((sum, current) => {
          const metroName = current.name;
          if (sum.includes(metroName)) return sum;
          return [...sum, metroName];
        }, []);
        return metro.sort();
      });
    }

    return await that.get('ajax').request(`/questrooms/subway`).then(data => {
      const metro = data.reduce((sum, current) => {
        const metroName = current.name;
        if (sum.includes(metroName)) return sum;
        return [...sum, metroName];
      }, []);
      return metro.sort();
    });
  }
});