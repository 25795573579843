define("questmap/models/playerbalance", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    createdAt: _emberData.default.attr('number'),
    type: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    sum: _emberData.default.attr('string'),
    fee: _emberData.default.attr('string'),
    balance: _emberData.default.attr('string'),
    agent: _emberData.default.attr('string'),
    player: _emberData.default.attr('string'),
    date: function () {
      return moment.utc(this.get('createdAt')).format('DD.MM.YY') + ' ' + moment.utc(this.get('createdAt')).format('HH:mm');
    }.property('createdAt')
  });

  _exports.default = _default;
});