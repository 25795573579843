define("questmap/templates/components/tables/orders/title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fJO3uI22",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[8],[1,[24,[\"row\",\"questroom_title\"]],false],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"text-sm\"],[8],[4,\"if\",[[24,[\"row\",\"not_save\"]]],null,{\"statements\":[[0,\" \"],[1,[24,[\"row\",\"profile_name\"]],false],[0,\" \"]],\"parameters\":[]},{\"statements\":[[0,\" \"],[1,[24,[\"row\",\"source\"]],false],[0,\" \"]],\"parameters\":[]}],[0,\" \"],[7,\"b\",true],[8],[1,[24,[\"row\",\"review_delta\"]],false],[9],[9],[0,\" \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/components/tables/orders/title.hbs"
    }
  });

  _exports.default = _default;
});