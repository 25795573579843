define("questmap/routes/app/mobile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),

    model() {
      return {
        title: '',
        text: '',
        image: '',
        user: ''
      };
    },

    async setupController(controller, model) {
      console.log(model);
    },

    actions: {
      sendPushToUser(model) {
        if (!model.user) {
          this.alerts.showAlert('error', 'Введите почту пользователя!');
          return;
        }

        this.get('ajax').request('pushtokens/sendPushToUser', {
          method: 'POST',
          data: model
        }).then(r => {
          console.log(r, "response");
          this.alerts.showAlert('success', 'Отправлено только ' + model.user + '!');
        }).catch(err => {
          console.error(err);
          this.alerts.showAlert('error', 'Ошибка');
        });
      },

      sendPushToAllUsers(model) {
        this.get('ajax').request('/pushtokens/sendPushToAllUsers', {
          method: 'POST',
          data: model
        }).then(r => {
          console.log(r, "response");
          if (r.error) throw new Error("ответ сервера: " + r.error);
          this.alerts.showAlert('success', 'Отправлено всем пользователям!');
        }).catch(err => {
          console.error(err);
          this.alerts.showAlert('error', 'Ошибка');
        });
      }

    }
  });

  _exports.default = _default;
});