define("questmap/templates/app/agents/view/payments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kYmpPM7R",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"unless\",[[24,[\"modal\",\"refresh\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"tables/table-index\",null,[[\"columns\",\"height\",\"constParams\",\"modelName\"],[[24,[\"columns\"]],\"70vh\",[24,[\"constParams\"]],\"agentadjustment\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/app/agents/view/payments.hbs"
    }
  });

  _exports.default = _default;
});