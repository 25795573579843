define("questmap/components/tables/orders/fee", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    val: Ember.computed('value', function () {
      console.log(this.get('value'));
      return numberWithSpaces(this.get('value'));
    })
  });

  _exports.default = _default;

  function numberWithSpaces(x) {
    console.log(x);
    if (!x) return '-';
    let z = x > 0 ? '' : '';
    return z + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' р';
  }
});