define("questmap/routes/app/partners/view/places/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return this.store.findRecord("place", params.place_id);
    },

    async setupController(controller, model) {
      const categories = this.store.findAll("place-category");
      controller.set('model', model);
      controller.set('categories', categories);
    },

    actions: {
      willTransition(transition) {
        this.controllerFor(transition.from.name).get("model").rollbackAttributes();
      }

    }
  });

  _exports.default = _default;
});