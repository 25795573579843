define("questmap/components/tables/table-index", ["exports", "ember-light-table"], function (_exports, _emberLightTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    observer
  } = Ember;

  var _default = Ember.Component.extend({
    skip: -20,
    limit: 20,
    sort: 'createdAt DESC',
    table: null,
    records: null,
    isLoading: false,
    canLoadMore: true,
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    modal: Ember.inject.service(),
    changeParams: observer('searchParams', function () {
      this.clearTable();
      this.fetchRecords();
    }),

    clearTable() {
      this.setProperties({
        skip: 0
      });
      this.get('table').setRows([]);
    },

    init() {
      this._super(...arguments);

      this.set('table', _emberLightTable.default.create({
        columns: this.columns
      }));
    },

    fetchRecords() {
      var prop = this.getProperties(['skip', 'limit', 'sort']);
      var params = {};

      if (this.textParams) {
        Object.assign(params, this.textParams);
      } else {
        if (this.searchParams) Object.assign(params, this.searchParams);
      }

      if (this.constParams) Object.assign(params, this.constParams);
      prop.where = params;
      this.set('isLoading', true);
      this.get('store').query(this.get('modelName'), prop).then(records => {
        this.get('table').addRows(records);
        this.set('isLoading', false);
        this.set('canLoadMore', !!records.length);
      });
    },

    actions: {
      onScrolledToBottom() {
        if (this.canLoadMore) {
          this.incrementProperty('skip', 20);
          this.fetchRecords();
        }
      },

      search() {
        let val = this.searchValue;
        if (!val) return this.send('clearSearch');
        this.clearTable();
        var q = createParams(this.modelName, val);
        this.set('textParams', val ? q : false);
        this.fetchRecords();
      },

      clearSearch() {
        this.clearTable();
        this.set('searchValue', null);
        this.set('textParams', null);
        this.set('canLoadMore', true);
        this.fetchRecords();
      },

      checkSearchParams(params) {
        if (!params) this.send('clearSearch');
      },

      onRowClick(model) {
        if (this.link) return this.router.transitionTo(this.link, model.content);

        if (this.modalPath) {
          if (this.modelForModal) {
            this.modal.open(this.modalPath, false, this.modelForModal, model.get(this.modelForModal));
          } else {
            this.modal.open(this.modalPath, model.content);
          }
        }
      },

      onColumnClick(column) {
        if (column.sorted) {
          const colGet = column.get('valuePath');

          if (colGet === 'cityFront') {
            this.set('sort', 'city' + (column.ascending ? ' asc' : ' desc'));
          } else {
            this.set('sort', colGet + (column.ascending ? ' asc' : ' desc'));
          }

          this.clearTable();
          this.fetchRecords();
        }
      }

    }
  });

  _exports.default = _default;

  const createParams = function (modelName, val) {
    if (val.split(' ').length == 1) {
      val = [val.toUpperCase(), val.toLowerCase(), val.charAt(0).toUpperCase() + val.slice(1).toLowerCase()];
    } else {
      val = [val];
    }

    let obj = {
      or: []
    };
    let rowsForSearch = {
      player: ['name', 'surname', 'email', 'phone', 'card'],
      questroom: ['title', 'company'],
      profile: ['title', 'group_title'],
      review: ['player_name', 'description'],
      order: ['fullname', 'sid', 'phone'],
      agent: ['phone', 'email', 'surname', 'name']
    };
    if (!rowsForSearch[modelName]) alert('Параметры поиска не установлены');
    rowsForSearch[modelName].forEach(r => {
      val.forEach(v => {
        obj.or.push({
          [r]: {
            contains: v
          }
        });
      });
    });
    return obj;
  };
});