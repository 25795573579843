define("questmap/templates/app/profiles/view/questrooms/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "motB/PST",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"questrooms-form\",null,[[\"model\",\"review\",\"save\",\"destroyModel\"],[[24,[\"model\",\"questroom\"]],[24,[\"model\",\"review\"]],\"save\",\"destroyModel\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/app/profiles/view/questrooms/edit.hbs"
    }
  });

  _exports.default = _default;
});