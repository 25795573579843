define("questmap/templates/modals/client", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ot0EFbza",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modals/modal-window\",null,[[\"title\",\"model\",\"removeModal\"],[\"Редактировать клиента\",[24,[\"model\"]],\"removeModal\"]],{\"statements\":[[0,\"\\t\"],[1,[28,\"bootstrap/form-control\",null,[[\"value\",\"label\"],[[24,[\"model\",\"name\"]],\"Имя\"]]],false],[0,\"\\n\\t\"],[1,[28,\"bootstrap/form-control\",null,[[\"value\",\"label\"],[[24,[\"model\",\"phone\"]],\"Телефон\"]]],false],[0,\"\\n\"],[0,\"\\t\"],[1,[28,\"bootstrap/form-control\",null,[[\"value\",\"label\"],[[24,[\"model\",\"email\"]],\"Почта\"]]],false],[0,\"\\n\\t\"],[1,[28,\"bootstrap/form-control\",null,[[\"value\",\"label\"],[[24,[\"model\",\"birthday\"]],\"День рождения\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/modals/client.hbs"
    }
  });

  _exports.default = _default;
});