define("questmap/routes/app/magic/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return this.store.findRecord('magic', params.magician_id);
    },

    actions: {
      save(m) {
        m.save();
      },

      delete(m) {
        m.destroyRecord();
      }

    }
  });

  _exports.default = _default;
});