define("questmap/services/ajax", ["exports", "ember-ajax/services/ajax", "questmap/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    host: _environment.default.host,
    session: Ember.inject.service(),
    headers: Ember.computed('session.data.authenticated.access_token', 'host', {
      get() {
        let headers = {};
        const authToken = "Bearer " + this.get('session.data.authenticated.access_token');

        if (authToken) {
          headers["Authorization"] = authToken;
        } // headers['Accept-Language'] = this.get('session.data.authenticated.locale') || ENV.lang


        return headers;
      }

    })
  });

  _exports.default = _default;
});