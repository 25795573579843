define("questmap/templates/components/bootstrap/form-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t/+GYiZn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n  \"],[7,\"label\",true],[10,\"class\",\"control-label\"],[8],[1,[22,\"label\"],false],[9],[0,\"\\n  \"],[1,[28,\"bootstrap-datepicker\",null,[[\"value\",\"valueFormat\",\"language\",\"startView\",\"class\"],[[24,[\"value\"]],\"date\",\"ru\",\"year\",\"datepicker form-control\"]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/components/bootstrap/form-date.hbs"
    }
  });

  _exports.default = _default;
});