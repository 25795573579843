define("questmap/components/tables/link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    id: Ember.computed(function () {
      return this.row.get(this.column.linkId);
    })
  });

  _exports.default = _default;
});