define("questmap/templates/app/magic/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0GPzD/ZS",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"input\",null,[[\"value\"],[[24,[\"model\",\"title\"]]]]],false],[0,\"\\n\"],[7,\"button\",false],[3,\"action\",[[23,0,[]],\"save\",[24,[\"model\"]]]],[8],[0,\"save\"],[9],[0,\"\\n\"],[7,\"button\",false],[3,\"action\",[[23,0,[]],\"delete\",[24,[\"model\"]]]],[8],[0,\"delete\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/app/magic/edit.hbs"
    }
  });

  _exports.default = _default;
});