define("questmap/models/player", ["exports", "@ember-data/model", "ember-data", "ember-cp-validations"], function (_exports, _model, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('cantblank'),
    email: (0, _emberCpValidations.validator)('format', {
      type: 'email',
      allowNonTld: true,
      message: 'Не верный формат email адреса'
    }),
    phone: (0, _emberCpValidations.validator)('phone'),
    surname: (0, _emberCpValidations.validator)('cantblank') // profile_name: validator('cantblank'),

  });

  var _default = _model.default.extend(Validations, {
    name: (0, _model.attr)('string'),
    surname: (0, _model.attr)('string'),
    promocode: (0, _model.attr)('string'),
    // agent: attr('string'),
    agent: _emberData.default.belongsTo('agent', {
      async: true
    }),
    phone: (0, _model.attr)('phone'),
    email: (0, _model.attr)('string'),
    card: (0, _model.attr)('string'),
    trust: (0, _model.attr)('number'),
    balance: (0, _model.attr)('number'),
    canceled_orders_count: (0, _model.attr)('number'),
    accepted_orders_count: (0, _model.attr)('number'),
    orders_count: (0, _model.attr)('number'),
    reviews_count: (0, _model.attr)('number'),
    place_reviews_count: (0, _model.attr)('number'),
    createdAt: (0, _model.attr)('number'),
    last_visit: (0, _model.attr)('number'),
    last_order: (0, _model.attr)('number'),
    not_save: (0, _model.attr)('boolean'),
    profile_name: (0, _model.attr)('string'),
    fullname: Ember.computed('name', 'surname', function () {
      return `${this.name} ${this.surname}`;
    }),
    not_accepted_orders: Ember.computed('name', 'surname', function () {
      return this.orders_count - this.canceled_orders_count - this.accepted_orders_count;
    }),
    createdAtHuman: Ember.computed('createdAt', function () {
      return moment(this.createdAt).format('DD MMMM YYYY в HH:mm');
    }),
    lastVisitHuman: Ember.computed('last_visit', function () {
      return moment(this.last_visit).format('DD MMMM YYYY в HH:mm');
    }),
    lastOrderHuman: Ember.computed('last_order', function () {
      return moment(this.last_order).format('DD MMMM YYYY в HH:mm');
    }),
    rank: Ember.computed('balance', function () {
      let rank = this.get('balance');
      rank = +rank || 0;
      if (rank < 1500) return {
        description: 1500 - rank + " баллов до Мичмана!",
        discount: 10,
        title: "Матрос",
        percents: Math.floor(rank * 100 / 1500),
        img: '/assets/img/ranks/matros.png'
      };
      if (rank < 5000) return {
        description: 5000 - rank + " баллов до скидки 15%",
        discount: 12,
        title: "Мичман",
        percents: Math.floor(rank * 100 / 5000),
        img: '/assets/img/ranks/michman.png'
      };
      if (rank < 10000) return {
        description: 10000 - rank + " баллов до скидки 20%",
        discount: 15,
        title: "Лейтенант",
        percents: Math.floor(rank * 100 / 10000),
        img: '/assets/img/ranks/leitenant.png'
      };
      if (rank < 15000) return {
        description: 15000 - rank + " баллов до скидки 25%",
        discount: 20,
        title: "Капитан",
        percents: Math.floor(rank * 100 / 15000),
        img: '/assets/img/ranks/kapitan.png'
      };
      if (rank < 25000) return {
        description: 25000 - rank + " баллов до скидки 30%",
        discount: 25,
        title: "Генерал",
        percents: Math.floor(rank * 100 / 25000),
        img: '/assets/img/ranks/general.png'
      };
      return {
        description: "У вас максимальный ранг!",
        discount: 30,
        percents: 100,
        title: "Адмирал флота",
        img: '/assets/img/ranks/admiral.png'
      };
    }),
    city: (0, _model.attr)('string'),
    cityFront: Ember.computed('city', function () {
      if (this.city === undefined) {
        return 'Москва';
      }

      return `${this.city}`;
    })
  });

  _exports.default = _default;
});