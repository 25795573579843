define("questmap/routes/app/profiles/view/orders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return this.modelFor("app.profiles.view").get('id');
    },

    async setupController(controller, model) {
      controller.set('columns', [{
        label: '',
        valuePath: 'log_status',
        cellComponent: 'tables/orders/status',
        width: '10px'
      }, {
        label: 'Квест',
        valuePath: 'questroom_title',
        cellComponent: 'tables/orders/title'
      }, {
        label: 'Дата',
        valuePath: 'date',
        cellComponent: 'tables/orders/date'
      }, {
        label: 'Клиент',
        valuePath: 'phone',
        cellComponent: 'tables/orders/player',
        width: '200px'
      }, {
        label: 'К оплате',
        valuePath: 'sum_to_pay',
        cellComponent: 'tables/orders/cost'
      }, {
        label: 'Статус',
        valuePath: 'status',
        cellComponent: 'tables/table-accept',
        width: '60px'
      }, {
        label: 'Оплата',
        valuePath: 'online_payment',
        width: '30px'
      }]);
      let questrooms = await this.store.query("questroom", {
        profile: model
      });
      controller.set('questrooms', questrooms);
      controller.set('constParams', {
        profile: model
      });
    }

  });

  _exports.default = _default;
});