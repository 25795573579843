define("questmap/templates/app/agents/view/clients", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MA9F+c3o",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[1,[28,\"tables/table-index\",null,[[\"columns\",\"height\",\"link\",\"constParams\",\"modelName\"],[[24,[\"columns\"]],\"70vh\",\"app.players.view.index\",[24,[\"constParams\"]],\"player\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/app/agents/view/clients.hbs"
    }
  });

  _exports.default = _default;
});