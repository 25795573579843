define("questmap/controllers/modals/all_reviews", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    alerts: Ember.inject.service(),
    modal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    // get_reviews: function(){
    //   console.log('wow')
    //   return this.store.query('review', { questroom_title: title, limit: 1000 }).then(function(review) {
    //     console.log("review", review)
    //   });
    // },
    reviews: Ember.computed('model', async function (reviews) {
      return await this.get('ajax').request('/reviews?limit=-1').then(r => {
        console.log(r);
        return r;
      }); // console.log(data);
      // return data;
      // return res;
      // return [
      //   {
      //     "createdAt": 1603294208836,
      //     "updatedAt": 1606816009091,
      //     "id": "5f905400dd4a4b148cb2b2c5",
      //     "description": "Квест для любителей острых🌶 ощущений. Даже слишком острых, один из наших товарищей от переизбытка эмоций даже сознание потерял🙈 в общем страшно, очень, актёры огонь,🔥 работают на износ. куча эмоций. советую, квест 5 из 5!",
      //     "audioUrl": null,
      //     "emotion": 4,
      //     "delta": 10,
      //     "createdDate": 1599253200000,
      //     "atmosphere": 10,
      //     "clean": 10,
      //     "mobility": 10,
      //     "story": 10,
      //     "puzzle": 10,
      //     "personal": 10,
      //     "questroom": "5ee0ee40a743ff13744e1468",
      //     "questroom_title": "Проект PSYCHO",
      //     "order": null,
      //     "player": null,
      //     "player_orders": "2",
      //     "player_name": "ТАТЬЯНА 77",
      //     "player_rank": "Матрос",
      //     "hidden": true,
      //     "fake": true,
      //     "accepted": true
      //   },
      //   {
      //     "createdAt": 1603294359688,
      //     "updatedAt": 1606816009093,
      //     "id": "5f905497dd4a4b148cb2b2c6",
      //     "description": "Отличный 👍квест. Испытали настоящий животный страх. Местами думали что расправы зомби🧟‍♀️ нам не избежать. Декорации супер реалистичные. Работа актёров просто великолепна🌟, оператор тоже 5 баллов! Приятно, что после игры организаторы с неподдельным интересом и участием расспрашивали нас о полученных впечатлениях, т. к. постоянно улучшают свой продукт! Респект👍",
      //     "audioUrl": null,
      //     "emotion": 4,
      //     "delta": 10,
      //     "createdDate": 1598648400000,
      //     "atmosphere": 10,
      //     "clean": 10,
      //     "mobility": 10,
      //     "story": 10,
      //     "puzzle": 10,
      //     "personal": 10,
      //     "questroom": "5ee0ee40a743ff13744e1468",
      //     "questroom_title": "Проект PSYCHO",
      //     "order": null,
      //     "player": null,
      //     "player_orders": "2",
      //     "player_name": "ЕКАТЕРИНА МУРАВЬЕВА",
      //     "player_rank": "Матрос",
      //     "hidden": true,
      //     "fake": true,
      //     "accepted": true
      //   }]
    }),
    actions: {
      get_reviews(title) {
        console.log('wowowowow');
      }

    }
  });

  _exports.default = _default;
});