define("questmap/routes/app/partners/view/orders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    modal: Ember.inject.service(),

    model() {
      return this.modelFor("app.partners.view").get("id");
    },

    async setupController(controller, model) {
      controller.set("columns", [{
        label: "Заведение",
        valuePath: "place_title"
      }, {
        label: "Дата",
        valuePath: "createdAt",
        cellComponent: "tables/unix"
      }, {
        label: "Клиент",
        valuePath: "player_card" // cellComponent: "tables/orders/player",

      }, {
        label: "Уникальный",
        valuePath: "uniq",
        cellComponent: "tables/true-false"
      }, {
        label: "Скидка",
        valuePath: "player_discount"
      }, {
        label: "ЧЖ",
        valuePath: "hasBlackPearl",
        cellComponent: "tables/true-false"
      }, {
        label: "БЖ",
        valuePath: "hasWhitePearl",
        cellComponent: "tables/true-false"
      }, {
        label: "Отзыв",
        valuePath: "delta",
        align: "center"
      }]);
      let places = await this.store.query("place", {
        partner: model
      });
      controller.set("places", places);
      controller.set("constParams", {
        partner: model
      });
    }

  });

  _exports.default = _default;
});