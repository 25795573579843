define("questmap/templates/app/partners-reviews", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UKdJhfRe",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"content-title\",null,[[\"title\"],[\"Отзывы партнеров\"]]],false],[0,\"\\n \\n\"],[1,[28,\"filters/reviews\",null,[[\"searchParams\",\"range\"],[[24,[\"searchParams\"]],[24,[\"range\"]]]]],false],[0,\"\\n\\n\"],[4,\"unless\",[[24,[\"modal\",\"refresh\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"tables/table-index\",null,[[\"columns\",\"searchParams\",\"modalPath\",\"height\",\"modelName\"],[[24,[\"columns\"]],[24,[\"searchParams\"]],\"modals/place-review\",\"60vh\",\"place-review\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/app/partners-reviews.hbs"
    }
  });

  _exports.default = _default;
});