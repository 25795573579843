define("questmap/templates/modals/all_reviews", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SSCuD8VG",
    "block": "{\"symbols\":[\"form\",\"review\",\"@title\",\"@model\"],\"statements\":[[4,\"modal-window\",null,[[\"size\"],[\"lg\"]],{\"statements\":[[0,\"\\t\"],[5,\"bs-form\",[[12,\"class\",\"modal-lg\"]],[[\"@onSubmit\"],[[28,\"action\",[[23,0,[]],[23,0,[\"modal\",\"saveModal\"]]],null]]],{\"statements\":[[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"flexRow\"],[8],[0,\"\\n\\t\\t\\n      \"],[1,[22,\"title\"],false],[0,\"\\n      \"],[1,[23,3,[]],false],[0,\"\\n    \"],[7,\"ol\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,4,[]]],null,{\"statements\":[[0,\"        \"],[7,\"li\",true],[8],[0,\"\\n          \"],[7,\"button\",false],[12,\"data-ember-action\",\"\"],[12,\"data-ember-action-600\",\"600\"],[12,\"style\",\"float: right;\"],[3,\"action\",[[23,0,[]],[23,0,[\"modal\",\"open\"]],\"modals/review\",[23,2,[]]]],[8],[0,\"\\n              \"],[7,\"i\",true],[10,\"class\",\"fa fa-pencil\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n          \"],[9],[0,\"\\n          \"],[1,[23,2,[\"description\"]],false],[0,\" \"],[7,\"br\",true],[8],[9],[0,\"\\n          \"],[7,\"b\",true],[8],[1,[23,2,[\"player_rank\"]],false],[0,\" - \"],[1,[23,2,[\"player_name\"]],false],[0,\",  \"],[1,[23,2,[\"player_orders\"]],false],[0,\" игр, оценка \"],[1,[23,2,[\"delta\"]],false],[0,\", \"],[1,[23,2,[\"createdDate\"]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"hr\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/modals/all_reviews.hbs"
    }
  });

  _exports.default = _default;
});