define("questmap/routes/app/partners-reviews", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    crud: Ember.inject.service(),

    beforeModel() {
      if (this.currentUser.user.role !== 'Супер пользователь') this.transitionTo('index');
    },

    async setupController(controller) {
      controller.set('columns', [{
        label: 'Заведение',
        valuePath: 'place_title'
      }, {
        label: 'Игрок',
        valuePath: 'player_name',
        cellComponent: 'tables/reviews/player',
        width: "200px"
      }, {
        label: 'Дата',
        valuePath: 'createdDate',
        cellComponent: 'tables/unix'
      }, {
        label: 'Оценка',
        valuePath: 'delta'
      }, {
        label: 'Отзыв',
        valuePath: 'description',
        cellComponent: 'tables/reviews/review'
      }, {
        label: 'Черная жемч',
        valuePath: 'blackPearl',
        cellComponent: 'tables/true-false'
      }, {
        label: 'Белая жемч',
        valuePath: 'whitePearl',
        cellComponent: 'tables/true-false'
      }, {
        label: 'Одобр',
        valuePath: 'accepted',
        cellComponent: 'tables/true-false',
        align: "center",
        width: "100px"
      }]);
    }

  });

  _exports.default = _default;
});