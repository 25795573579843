define("questmap/templates/app/partners/view/places/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NtIlAiMu",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\"],[\"button\",\"app.partners.view.places.create\"]],{\"statements\":[[0,\"    Новое заведение \\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"tables/table-index\",null,[[\"columns\",\"height\",\"hideSearch\",\"constParams\",\"modelName\"],[[24,[\"columns\"]],\"80vh\",true,[24,[\"constParams\"]],\"place\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/app/partners/view/places/index.hbs"
    }
  });

  _exports.default = _default;
});