define("questmap/templates/modals/place-order-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7qq/i0gk",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[4,\"modal-window\",null,[[\"model\",\"size\"],[[24,[\"model\"]],\"lg\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"flexRow\"],[8],[0,\"\\n    \"],[7,\"h4\",true],[8],[0,\"Бронь в заведение \"],[1,[24,[\"model\",\"place\",\"title\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[5,\"bs-form\",[],[[\"@model\",\"@onSubmit\"],[[22,\"model\"],[28,\"action\",[[23,0,[]],[23,0,[\"modal\",\"saveModal\"]]],null]]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n      \"],[6,[23,1,[\"element\"]],[[12,\"class\",\"col-md-12\"]],[[\"@controlType\",\"@label\",\"@property\"],[\"text\",\"ID игрока\",\"player_id\"]]],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"flexRow\"],[8],[0,\"\\n        \"],[5,\"bs-button\",[],[[\"@defaultText\",\"@buttonType\",\"@type\",\"@disabled\"],[\"Создать\",\"submit\",\"primary\",[23,1,[\"isSubmitting\"]]]]],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/modals/place-order-new.hbs"
    }
  });

  _exports.default = _default;
});