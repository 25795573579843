define("questmap/templates/app/orders/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rz+DblfX",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"content-title\",null,[[\"title\"],[\"Бронирования\"]]],false],[0,\"\\n\"],[1,[28,\"filters/orders\",null,[[\"searchParams\"],[[24,[\"searchParams\"]]]]],false],[0,\"\\n\\n  \"],[1,[28,\"tables/table-index\",null,[[\"columns\",\"searchParams\",\"height\",\"modalPath\",\"modelName\"],[[24,[\"model\"]],[24,[\"searchParams\"]],\"60vh\",\"modals/orderdetails\",\"order\"]]],false],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/app/orders/index.hbs"
    }
  });

  _exports.default = _default;
});