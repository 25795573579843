define("questmap/routes/app/profiles/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    crud: Ember.inject.service(),

    async model(params) {
      return await this.store.findRecord('profile', params.profile_id);
    },

    setupController(controller, model) {
      this.set('crud.modelForUpdate', model);
      controller.set('model', model);
    }

  });

  _exports.default = _default;
});