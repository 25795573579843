define("questmap/templates/app/partners/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zRfP1q5R",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"content-title\",null,[[\"path\",\"model\",\"title\",\"icon\"],[\"modals/partner\",[24,[\"model\"]],[24,[\"model\",\"title\"]],\"pencil\"]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"model\",\"deactivated\"]]],null,{\"statements\":[[0,\"\\tПАРТНЕР ЗАБЛОКИРОВАН \"],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"viewNavWrap\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[8],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"route\"],[\"app.partners.view.index\"]],{\"statements\":[[0,\"Данные\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"route\"],[\"app.partners.view.users\"]],{\"statements\":[[0,\"Пользователи\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"route\"],[\"app.partners.view.places.index\"]],{\"statements\":[[0,\"Заведения (\"],[1,[24,[\"model\",\"places\",\"length\"]],false],[0,\")\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"route\"],[\"app.partners.view.orders\"]],{\"statements\":[[0,\"Заявки (\"],[1,[24,[\"model\",\"orders\"]],false],[0,\" / \"],[1,[24,[\"model\",\"uniqOrders\"]],false],[0,\")\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"route\"],[\"app.partners.view.reviews\"]],{\"statements\":[[0,\"Отзывы (\"],[1,[24,[\"model\",\"reviewsCount\"]],false],[0,\" / \"],[1,[24,[\"model\",\"blackPearlsCount\"]],false],[0,\" / \"],[1,[24,[\"model\",\"whitePearlsCount\"]],false],[0,\")\"]],\"parameters\":[]},null],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/app/partners/view.hbs"
    }
  });

  _exports.default = _default;
});