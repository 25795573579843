define("questmap/components/bg-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // 425x282,220x150,640x430,1200x800
  var _default = Ember.Component.extend({
    attributeBindings: ['style'],
    classNames: ['bg-image'],
    classNameBindings: ['is_waiting'],
    is_waiting: Ember.computed('loadingImage', function () {
      return !this.loadingImage ? 'animated-background' : false;
    }),
    style: Ember.computed('loadingImage', function () {
      return this.loadingImage ? 'background-image: url(' + this.loadingImage + ')' : '';
    }),

    didInsertElement() {
      this.checkImage();
    },

    changeImage: Ember.observer('image', function () {
      this.set('loadingImage', null);
      this.checkImage();
    }),

    checkImage() {
      let img = this.image || '';
      img = img.split('1200x800.jpg');

      if (img.length < 2) {
        this.set('loadingImage', this.image);
      } else {
        img = img[0] + this.size + '.jpg' + img[1];
        this.loadImage(img);
      }
    },

    loadImage(src) {
      const that = this;
      var ts = Date.now(),
          img = new Image();

      img.onerror = function () {
        if (Date.now() - ts < 10000) {
          setTimeout(function () {
            img.src = src;
          }, 1000);
        }
      };

      img.onload = function () {
        that.set('loadingImage', src);
      };

      img.src = src;
    }

  });

  _exports.default = _default;
});