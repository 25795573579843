define("questmap/templates/components/tables/reviews/player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8lP8sllW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[8],[1,[24,[\"row\",\"player_name\"]],false],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"text-sm\"],[8],[1,[24,[\"row\",\"player_rank\"]],false],[0,\", кол-во игр: \"],[1,[24,[\"row\",\"player_orders\"]],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "questmap/templates/components/tables/reviews/player.hbs"
    }
  });

  _exports.default = _default;
});