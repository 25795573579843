define("questmap/components/elements/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    formatDate: Ember.computed("value", function () {
      return this.value ? moment(this.value).format('DD.MM.YY') : moment().format('DD.MM.YY');
    }),
    actions: {
      changeDate(date) {
        console.log(date.date);
        this.set('date', moment(date.date).toDate());
        this.set('value', moment(date.date).valueOf());
      }

    }
  });

  _exports.default = _default;
});