define("questmap/components/visual-redactor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function reconstractHtmlTree(value) {
    const doc = new DOMParser().parseFromString(value, "text/html");
    const divs = doc.querySelectorAll("body > div");
    divs.forEach(div => div.outerHTML = `<p>${div.innerText}</p>`);
    return new XMLSerializer().serializeToString(doc);
  }

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);

      const {
        value
      } = arguments[0];
      this.value = reconstractHtmlTree(value);
    },

    didInsertElement() {
      const div = `#${this.$()[0].id} #v_editor`;
      const editorConf = {
        editorResizeMode: "none",
        readOnly: false
      };
      editorConf.toolbar = "mytoolbar";
      editorConf.toolbarMobile = "mytoolbar";
      editorConf.toolbar_mytoolbar = "{bold,italic,underline}|{forecolor,backcolor}|removeformat|{insertunorderedlist, Indent, Outdent}#|{lineheight}{undo,redo}";
      this.v_editor = new window.RichTextEditor(div, editorConf);
      this.v_editor.setHTMLCode(this.value);
      this.v_editor.attachEvent('change', () => {
        this.set('value', this.v_editor.getHTMLCode());
      });
    },

    actions: {}
  });

  _exports.default = _default;
});