define("questmap/routes/app/reviews", ["exports", "bootstrap"], function (_exports, _bootstrap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),

    beforeModel() {
      if (this.currentUser.user.role !== 'Супер пользователь') this.transitionTo('index');
    },

    async setupController(controller) {
      controller.set('columns', [{
        label: 'Игрок',
        valuePath: 'player_name',
        cellComponent: 'tables/reviews/player',
        width: "200px"
      }, {
        label: 'Дата',
        valuePath: 'createdDate',
        cellComponent: 'tables/unix'
      }, {
        label: 'Оценка',
        valuePath: 'delta',
        cellComponent: 'tables/reviews/questroom'
      }, {
        label: 'Отзыв',
        valuePath: 'description',
        cellComponent: 'tables/reviews/review'
      }, {
        label: 'Аудио',
        valuePath: 'audioUrl',
        cellComponent: 'tables/true-false',
        align: "center",
        width: "100px"
      }, {
        label: 'Одобр',
        valuePath: 'accepted',
        cellComponent: 'tables/true-false',
        align: "center",
        width: "100px"
      }]);
    },

    ajax: Ember.inject.service(),
    actions: {
      async updateReviews() {
        await this.get('ajax').request(`/reviews/updateReviews`);
        alert('Отзывы обновлены');
      }

    }
  });

  _exports.default = _default;
});